<template>
<v-simple-table style="width:100%" >
  <thead>
    <tr>
      <th class="px-2 pl-4"><b>Nom</b></th>
      <th class="px-1">Matériaux</th>
      <th class="px-1">Valeur</th>
    </tr>
  </thead>
  <tbody>
    <template v-for="production in $store.state.app.catalog.houses">
    <tr :key="production._id">
      <td :class="production.productOut.iconColor+'--text'">
        <v-icon class="mr-1" :color="production.productOut.iconColor">mdi-{{ production.productOut.icon }}</v-icon>
        <br v-if="$vuetify.breakpoint.width < 600">
        <b>{{ production.productOut.name }}</b>
      </td>
      <td>
        <template v-for="productIn in production.productsIn">
          <span :key="productIn._id">
            <v-chip small outlined class="ma-1" :color="productIn.iconColor">
              <v-icon :color="productIn.iconColor" class="mr-1" small>mdi-{{ productIn.icon }}</v-icon>
              {{ production.stockIn[productIn.key] }} 
              {{ productIn.priceUnity.toLowerCase() != productIn.name.toLowerCase() ? productIn.priceUnity : '' }} {{ productIn.name }}
            </v-chip>
          </span>
        </template>
      </td>
      <td>{{ production.productOut.price }} òto/{{ production.productOut.priceUnity }}</td>
    </tr>
    </template>
  </tbody>
</v-simple-table>
</template>

<style></style>

<script>

//import axios from 'axios'
//import router from '../router/router'
//import core from '../plugins/core.js'

//import Post from '@/components/Post.vue'

export default {
  name: 'empty',
  components: {  },
  data: () => ({
    
    
  }),
  async mounted(){
    
  },
  methods: {
    

  },
  computed: {
    
    
  }
}
</script>
