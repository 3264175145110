<template>
<v-dialog  :fullscreen="$vuetify.breakpoint.width < 600" transition="dialog-bottom-transition"
            v-model="showDialog" max-width="800" scrollable content-class="dialog-medium-bordered dialog-magik"
  >
  <v-card id="dialogCaptcha">
    <v-toolbar elevation="0" max-height="60">
      <v-icon small class="mr-1">mdi-basket-fill</v-icon> <b>Mon stock</b>
      <v-spacer/>
      <v-btn fab elevation="0" x-small @click="$store.dispatch('auth/refreshUserData')" 
            title="actualiser la quantité de stocks disponibles de chaque produit (il est possible que les stocks aient changés depuis le chargement des données)"
            class="mr-2">
        <v-icon small class="">mdi-refresh</v-icon>
      </v-btn>
      <v-btn @click="showDialog = false" 
              outlined icon small class="mr-0">
              <v-icon>mdi-close</v-icon>
      </v-btn>
    </v-toolbar>

    <v-card-text class="px-0">
      <v-col cols="12" class="px-0">
        <v-simple-table v-if="totalInStock > -10">
          <template v-slot:default>
            <thead>
              <tr>
                <th class="px-2">Produit</th>
                <th class="px-1">
                  <span class="">
                    Quantité
                  </span>
                </th>
                <th class="px-1" v-if="$vuetify.breakpoint.width > 600">Prix</th>
                <th class="px-1" v-if="$vuetify.breakpoint.width > 600">Portions</th>
                <th class="px-1 blue--text">Bonus</th>
                <th class="px-1 pr-2 px-sm-3 text-right">Consommer</th>
              </tr>
            </thead>
            <tbody>
              <template v-for="(product, keyP) in $store.state.auth.user.stocks">
                <tr :key="product._id+'stock'" v-if="product.stock > -10 && product.isConsumable"
                     style="cursor:pointer" class=""
                     @click="openDialogConsume(keyP)">
                  <td class="px-2 px-sm-5 text-left" :class="product.iconColor+'--text'">
                    <v-icon class="mr-1" :color="product.iconColor">mdi-{{ product.icon }}</v-icon>
                    <!-- <br class="d-sm-none"> -->
                    <b>{{ product.name }}</b>
                  </td>
                  <td class="px-1">
                    <span class="">
                    {{ product.stock.toFixed(2) }} {{ product.priceUnity }}{{ product.priceUnity.length > 1 ? 's' : '' }}
                    </span>
                  </td>
                  <td class="px-1" v-if="$vuetify.breakpoint.width > 600">
                    <small class="">
                    {{ product.price }} òto/{{ product.priceUnity }}
                    </small>
                  </td>
                  <td class="px-1" v-if="$vuetify.breakpoint.width > 600">
                    <span class="">
                    {{ (product.stock * product.nbPortionsConso).toFixed(0) }}
                    </span>
                  </td>
                  <td class="px-1 blue--text">
                    <template v-if="product.services[0] != null">
                    <b>{{ product.services[0].scoreBonus > 0 ? '+'+product.services[0].scoreBonus : "-"+product.services[0].scoreBonus }}</b> 
                    <v-icon color="blue" class="ml-1">mdi-{{ product.services[0].scoreModel.icon }}</v-icon>
                    </template>
                  </td>
                  <td class="text-right">
                    <v-btn fab x-small elevation="0" :disabled="product.stock <= 0">
                      <v-icon>mdi-account-plus-outline</v-icon>
                    </v-btn>
                  </td>
                </tr>
              </template>
            </tbody>
          </template>
        </v-simple-table>

        <v-card class="pa-5 mt-5" color="red" elevation="0" v-if="totalInStock == 0">
          Vous n'avez aucun produit en stock.<br>
          Parcourez la carte pour trouver des produits à acheter !
        </v-card>
      </v-col>
    </v-card-text>

  </v-card>
  <DialogConsume :product="$store.state.auth.user.stocks[keyProduct]"/>
</v-dialog>
</template>

<style></style>

<script>

//import axios from 'axios'
//import router from '../router/router'
//import core from '../plugins/core.js'

//import Post from '@/components/Post.vue'
import DialogConsume from '@/components/dashboard/DialogConsume.vue'

export default {
  name: 'empty',
  components: { DialogConsume },
  data: () => ({
    showDialog: false,
    keyProduct: null
  }),
  async mounted(){    
    this.$root.$off('openDialogMyStock')
              .$on('openDialogMyStock', () => { 
      this.showDialog = true
    })
    this.$root.$off('closeDialogSearchGeo')
              .$on('closeDialogSearchGeo', () => { 
      this.showDialog = false
    })
  },
  methods: {
    
    openDialogConsume(keyP){
      this.keyProduct = keyP
      this.$root.$emit('openDialogConsume')
    }

  },
  computed: {
    totalInStock(){
      if(this.$store.state.auth.user.stocks == null) return 0
      let total = 0
      this.$store.state.auth.user.stocks.forEach((product)=>{
        total += product.stock
      })
      return total
    }
    
  }
}
</script>
