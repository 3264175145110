<template>
<v-dialog  :fullscreen="$vuetify.breakpoint.width < 960"
            v-model="showDialog" max-width="800"
            scrollable
  >
  <v-card id="dialogWallet" color="background_dark">
    <v-toolbar elevation="0">
      <!-- <v-icon  class="mr-1">mdi-view-dashboard-variant</v-icon>  -->
      <v-gravatar height="25" style="border-radius:50%; margin-bottom:-5px;"
                  :default-img="'retro'" :email="walletName" class="mr-1"/>
      Compte de <b class="ml-1">{{ walletName }}</b>
      <v-spacer/>
      <!-- <v-btn fab elevation="0" x-small @click="$store.dispatch('auth/refreshUserData')" 
            title="actualiser la quantité de stocks disponibles de chaque produit (il est possible que les stocks aient changés depuis le chargement des données)"
            class="mr-2">
        <v-icon small class="">mdi-refresh</v-icon>
      </v-btn> -->
      <v-btn @click="showDialog = false" 
              outlined icon small class="mr-0">
              <v-icon>mdi-close</v-icon>
      </v-btn>
    </v-toolbar>

    <v-card-text class="pt-5">
      <WalletView :wallet="wallet"/>
    </v-card-text>
  </v-card>
</v-dialog>
</template>

<script>

import WalletView from '@/components/wallet/WalletView.vue'
import Gravatar from 'vue-gravatar'

export default {
  components: { WalletView, 'v-gravatar' : Gravatar },
  data: () => ({
    showDialog: false,
    company: null,
    wallet: null
  }),
  async mounted(){    
    this.$root.$off('openDialogWallet')
              .$on('openDialogWallet', (company) => { 
      this.showDialog = true
      this.company = company
      this.wallet = company.walletDeposit
    })
    this.$root.$off('closeDialogWallet')
              .$on('closeDialogWallet', () => { 
      this.showDialog = false
      this.wallet = null
    })
  },
  methods: {
    

  },
  computed: {
    walletName(){ 
      if(this.wallet == null) return ""
      return this.wallet.ownerCompany != null ? this.wallet.ownerCompany.name : this.wallet.owner.name 
    }
    
  }
}
</script>
