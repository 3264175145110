<template>
<v-simple-table dense style="width:100%" >
  <thead>
    <tr>
      <th class="px-2 pl-4"><b>Entreprise ({{ $store.state.app.catalog.companies.length }})</b></th>
      <th class="px-1 text-center">Production</th>
      <th class="px-1" v-if="$vuetify.breakpoint.width > 600">Surface min</th>
      <th class="px-1" v-if="$vuetify.breakpoint.width > 600">Valeur /m2</th>
      <th class="px-1">Valeur min</th>
    </tr>
  </thead>
  <tbody>
    <template v-for="company in $store.state.app.catalog.companies">
    <tr :key="company._id">
      <td>
        <v-icon x-small class="mb-1 mr-1" :color="company.iconColor">mdi-circle</v-icon>
        <b>{{ company.name }}</b>
      </td>
      <td style="min-width:120px;">
        <template v-for="production in company.productions">
          <span :key="production._id">
            <v-icon :color="production.productOut.iconColor" class="ml-2" small>mdi-{{ production.productOut.icon }}</v-icon>
            <small :class="production.productOut.iconColor + '--text'">
              <!-- <b class="d-none d-sm-inline">{{ production.name }}</b> -->
            </small>
          </span>
        </template>
      </td>
      <td v-if="$vuetify.breakpoint.width > 600">{{ company.surfaceMin }} m2</td>
      <td v-if="$vuetify.breakpoint.width > 600">{{ company.surfacePrice }} òto/m2</td>
      <td>{{ company.surfacePrice * company.surface }} òto</td>
    </tr>
    </template>
  </tbody>
</v-simple-table>
</template>

<style></style>

<script>

//import axios from 'axios'
//import router from '../router/router'
//import core from '../plugins/core.js'

//import Post from '@/components/Post.vue'

export default {
  name: 'empty',
  components: {  },
  data: () => ({
    
    
  }),
  async mounted(){
    
  },
  methods: {
    

  },
  computed: {
    
    
  }
}
</script>
