<template>
<div>

  <v-simple-table style="background-color:transparent;" dense>
    <template v-slot:default>
      <tbody>
          <tr v-if="showProductionDetails">
            <td><b>Valeur brut</b></td>
            <td class="px-4 text-right">
                +{{ valeurMaxBrutByDay }} òto
            </td>
          </tr>
          <tr v-if="showProductionDetails">
            <td><b>Valeur net</b></td>
            <td class="px-4 text-right">
                +{{ valeurMaxReelByDay }} òto
            </td>
          </tr>
          <tr v-if="showProductionDetails">
            <td><b>Salaires</b></td>
            <td class="px-4 text-right">
                -{{ salaryCurrentCost }} òto
            </td>
          </tr>
          <tr :class="(benef >= 0 ? 'green' : 'red') + '--text'">
            <td><b>Bénéfices/jour</b></td>
            <td class="px-4 text-right">
                {{ benef }} òto
            </td>
          </tr>
      </tbody>
    </template>
  </v-simple-table>

</div>
</template>


<style></style>

<script>

export default {
  name: 'StatsProduction',
  props: ['company', 'production', 'productionsActive', 'showProductionDetails'],
  components: {  },
  data: () => ({
  }),
  async mounted(){
    
  },
  methods: {
    productionCost: function(){
     let cost = 0
     //console.log("productionCost", this.nbProductionsActive * this.coefStockByEmployeeCurrent)
     this.production.productsIn.forEach((product)=>{
       let coeffEmployee = 1 // (this.nbEmployeesByParcelleCurrent * this.production.coefStockByEmployee)
       cost += product.priceBuy * (this.production.stockIn[product.key] / coeffEmployee) / 
                (this.production.stockOut)
     }) 
     return cost.toFixed(6)
    },

  },
  computed: {

    valeurMaxBrutByDay(){
      console.log("valeurMaxBrutByDay", this.production.name, this.production.stockOut, this.nbProductionsActive,  this.coefStockByEmployeeCurrent,
                 (this.production.stockOut / this.nbProductionsActive) * this.coefStockByEmployeeCurrent, this.production.priceOut)
      //(production.stockOut / nbProductionActive * (nbEmployeesByParcelleCurrent * production.coefStockByEmployee)
      return ((this.production.stockOut / this.nbProductionsActive) * this.coefStockByEmployeeCurrent * this.production.priceOut).toFixed(6)
    },
    valeurMaxReelByDay(){
      return (this.valeurMaxBrutByDay - this.productionCost()).toFixed(6)
    },
    salaryMaxCost(){
      return (this.salaryEmployeesByDay * this.company.nbEmployeesByParcelle / this.nbProductionsActive).toFixed(6)
    },
    salaryCurrentCost(){
      return (this.salaryEmployeesByDay * this.company.employees.length / this.nbProductionsActive).toFixed(6)
    },
    benef(){
      return (this.valeurMaxReelByDay - this.salaryCurrentCost).toFixed(6)
    },


    nbEmployeesByParcelleCurrent: function(){
      return (this.company.employees.length+1) / (this.nbParcelles * this.company.nbEmployeesByParcelle)
    },
    coefStockByEmployeeCurrent: function(){
      //console.log("this.nbEmployeesByParcelleCurrent", this.nbEmployeesByParcelleCurrent * this.production.coefStockByEmployee)
      return this.nbEmployeesByParcelleCurrent * this.production.coefStockByEmployee
    },
    nbProductionsActive(){
      return this.productionsActive.length > 0 ? this.productionsActive.length : 1
    },

    nbParcelles(){
      return this.company.surface / this.company.surfaceParcelle
    }, 
    productionParcelleByDay(){
      return this.production.stockOut / this.nbProductionsActive
    },
    salaryEmployeesByMonth(){
      return (this.company.salaryEmployees)
    },
    salaryEmployeesByDay(){
      return (this.salaryEmployeesByMonth / 30.4375).toFixed(6)
    },
    CAParcelleByDay(){
      return (this.productionParcelleByDay * this.production.priceOut).toFixed(6)
    },
    salaryEmployeesParcelleByDay(){
      return (this.salaryEmployeesByDay * this.company.nbEmployeesByParcelle / this.nbProductionsActive).toFixed(6)
    },
    benefParcelleByDay(){
      return (this.CAParcelleByDay - this.salaryEmployeesParcelleByDay).toFixed(6)
    },
    CATotal(){
      return ( this.CAParcelleByDay * this.nbParcelles).toFixed(6)
    },
    benefTotalByDay(){
      return (this.benefParcelleByDay * this.nbParcelles).toFixed(6)
    },
    // nbProductionsActive(){
    //   return this.productionsActive.length
    // }
  }
}
</script>
