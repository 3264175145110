<template>
<v-dialog  :fullscreen="$vuetify.breakpoint.width < 600"
            v-model="showDialog" max-width="500"
            scrollable class="dialog-large-bordered"
  >
  <v-card id="DialogConsume" color="background_dark" v-if="product != null">
    <v-toolbar elevation="1">
      <v-icon  class="mr-1">mdi-view-dashboard-variant</v-icon> Votre stock de {{ product.name }}
      <v-spacer/>
      <v-btn fab elevation="0" x-small @click="$store.dispatch('auth/refreshUserData')" 
            title="actualiser la quantité de stocks disponibles"
            class="mr-2">
        <v-icon small class="">mdi-refresh</v-icon>
      </v-btn>
      <v-btn @click="showDialog = false" 
              outlined icon small class="mr-0">
              <v-icon>mdi-close</v-icon>
      </v-btn>
    </v-toolbar>

    <v-card-text class="pt-5">
      <v-row>
        <v-col cols="12" class="text-center">
          <v-card-title :class="product.iconColor+'--text'">
          <v-icon x-large :color="product.iconColor">mdi-{{ product.icon }}</v-icon> 
          <br>
          {{ product.name }}
          <br>
          <span class="font-3x">{{ product.stock.toFixed(2) }} {{ product.priceUnity }}</span>
          </v-card-title>
          <br>
          {{ nbPortions }} 
          portion{{ nbPortions>1?'s':'' }} 
          à consommer
          <br>
          <br>


          <template v-for="service in product.services">
            <v-chip :key="service._id" color="blue" outlined>
              <b>{{ service.scoreBonus > 0 ? '+'+service.scoreBonus*nbPortionsConsume : "-"+service.scoreBonus*nbPortionsConsume }} </b>
              <v-icon class="mx-1">mdi-{{ service.scoreModel.icon }}</v-icon>
              <b>{{ service.scoreModel.name }}</b>
            </v-chip>
          </template>

          <br><br>

          <v-slider v-if="nbPortions > 1" color="blue" 
                    v-model="nbPortionsConsume" 
                    :min="1" :max="maxPortions">
            <template v-slot:prepend>
              <v-icon @click="decrement">mdi-minus</v-icon>
            </template>

            <template v-slot:append>
              <v-icon @click="increment">mdi-plus</v-icon>
            </template>
          </v-slider>
          
          <v-btn color="blue" :disabled="product.stock <= 0" dark @click="startConsume()" :loading="loading">
            <v-icon>mdi-account-plus-outline</v-icon> 
            Consommer <template v-if="nbPortions > 1">{{ nbPortionsConsume }} portion</template>
          </v-btn>
        </v-col>
      </v-row>
    </v-card-text>

  </v-card>

</v-dialog>
</template>

<style></style>

<script>

import axios from 'axios'
//import router from '../router/router'
//import core from '../plugins/core.js'


export default {
  name: 'empty',
  props: ['product'],
  components: {  },
  data: () => ({
    showDialog: false,
    loading: false,
    nbPortionsConsume: 1
  }),
  async mounted(){    
    this.$root.$off('openDialogConsume')
              .$on('openDialogConsume', () => { 
      this.nbPortionsConsume = 1
      this.showDialog = true
    })
    this.$root.$off('closeDialogConsume')
              .$on('closeDialogConsume', () => { 
      this.nbPortionsConsume = 1
      this.showDialog = false
    })
  },
  methods: {
    async startConsume(){
      this.loading = true
      axios.post('/user/consume/product', { productId: this.product._id,
                                            nbPortions: this.nbPortionsConsume
                                          })
          .then(async (res) => {
            if(!res.data.error) {
              console.log("ok", res.data)
              await this.$store.dispatch("auth/refreshUserData")
              this.loading = false
              this.showDialog = false
            }
            else console.log("error fetching entity", res.data)
          })
          .catch((e)=> {
            this.loading = false
            console.log("error /user/consume/product", e)
          })
    },
    decrement: function(){
      if(this.nbPortionsConsume <= 1) return
      this.nbPortionsConsume -= 1
    },
    increment: function(){
      this.nbPortionsConsume += 1
    },
  },
  computed: {
    nbPortions(){ return this.product != null 
                      ? (this.product.stock * this.product.nbPortionsConso).toFixed(0)
                      : 0 
    },
    maxPortions(){
      return this.nbPortions >= 3 ? 3 : this.nbPortions
    }
    
  }
}
</script>
