<template>
<v-simple-table dense style="width:100%" >
  <thead>
    <tr>
      <th class="px-2 pl-4"><b>Produits ({{ $store.state.app.catalog.products.length }})</b></th>
      <th class="px-1">Services</th>
      <th class="px-1">Prix moyen</th>
    </tr>
  </thead>
  <tbody>
    <template v-for="product in $store.state.app.catalog.products">
    <tr :key="product._id" v-if="product.category != 'housing' && product.category != 'vehicle'">
      <td :class="product.iconColor+'--text'">
        <v-icon class="mr-1" :color="product.iconColor">mdi-{{ product.icon }}</v-icon>
        <b>{{ product.name }}</b>
      </td>
      <td>
        <template v-for="service in product.services">
          <span :key="service._id">
            <v-icon :color="service.scoreBonus >= 0 ? 'green' : 'red'" class="ml-2" small>mdi-{{ service.scoreModel.icon }}</v-icon>
            <small :class="service.scoreBonus >= 0 ? 'green--text' : 'red--text'">
              <b class="d-none d-sm-inline">{{ service.scoreBonus >= 0 ? "+" : "" }}{{ service.scoreBonus }}</b>
            </small>
          </span>
        </template>
      </td>
      <td>{{ product.price }} òto/{{ product.priceUnity }}</td>
    </tr>
    </template>
  </tbody>
</v-simple-table>
</template>

<style></style>

<script>

//import axios from 'axios'
//import router from '../router/router'
//import core from '../plugins/core.js'

//import Post from '@/components/Post.vue'

export default {
  name: 'empty',
  components: {  },
  data: () => ({
    
    
  }),
  async mounted(){
    
  },
  methods: {
    

  },
  computed: {
    
    
  }
}
</script>
