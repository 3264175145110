<template>
<v-dialog  fullscreen
            v-model="showDialog" max-width="1300"
            scrollable
  >
  <v-card id="dialogWallet" color="" v-if="company!=null">
    <v-toolbar elevation="0">
      <v-icon  class="mr-1">mdi-cogs</v-icon> 
      <b class="ml-1">Gérer mon entreprise</b>
      <v-spacer/>
      <v-btn fab elevation="0" x-small @click="$store.dispatch('auth/refreshUserData')" 
            title="actualiser les données"
            class="mr-2">
        <v-icon small class="">mdi-refresh</v-icon>
      </v-btn>
      <b :class="(totalBenef >= 0 ? 'green' : 'red') + '--text'" class="mr-4"
          title="estimation de la valeur totale de votre production /mois">
        {{ totalBenef >= 0 ? '+' : '' }}
        {{ (totalBenef * 30.4375).toFixed(6) }} òto/mois
      </b>
      <v-btn @click="showDialog = false" 
              outlined icon small class="mr-0">
              <v-icon>mdi-close</v-icon>
      </v-btn>
    </v-toolbar>

    <v-card-text class="pt-5">
      
          <v-row>
            <v-col cols="12" sm="6" md="4">
              <v-list class="py-0 pb-1">
                <v-list-item class="px-0">
                    <v-list-item-avatar height="56" width="56" :class="'mr-3'">
                      <v-gravatar height="40" style="border-radius:50%; margin-bottom:-6px;"
                                  :default-img="'retro'" :email="company.name"/>
                    </v-list-item-avatar>
                    
                    <v-list-item-content>

                      <v-list-item-title class="headline mb-1" style="line-height: 1.3rem;">
                        <span style="font-size:0.85em!important;line-height:1.2em!important;" 
                          :class="'pa-0'">
                          <b>{{ company.name }}</b>
                        </span>
                      </v-list-item-title>
                      
                      <v-list-item-subtitle v-show="company.address!=null ||  company.city!=null">
                        <v-icon small style="margin-bottom:3px;margin-right:-1px;" v-if="company.city != ''">mdi-map-marker</v-icon>
                        {{ company.address }} {{ company.city }}
                      </v-list-item-subtitle>

                      <v-list-item-subtitle v-if="company.category!=null">
                        <span :class="company.category.iconFaColor+'--text'">{{ company.category.name }}</span> - 
                        <b class="pr-2">{{ company.ownModel.name }}</b>
                      </v-list-item-subtitle>
                    </v-list-item-content>
                </v-list-item>
              </v-list>
            
              <v-btn @click="$root.$emit('openDialogWallet', company)" large 
                      class="mb-1 pa-1 px-2" elevation="0" style="font-size:18px!important"
                      v-if="company.walletDeposit.amountMony != null">
                <v-icon small class="mb-0 mr-1">mdi-wallet</v-icon>
                {{ company.walletDeposit.amountMony.toFixed(4) }} òto
              </v-btn>
              <span class="font-2x ml-2">· {{ company.walletDeposit.uid }}</span>
              <v-card-subtitle class="pa-0 my-1">
                <v-icon small class="mb-1 mr-1">mdi-texture-box</v-icon>
                {{ nbParcelles }} parcelle{{ nbParcelles>1?'s':'' }} de {{ company.surface }} m2
              </v-card-subtitle>
              <v-card-subtitle class="pa-0 my-1">
                <v-icon small class="mb-1 mr-1">mdi-account-tie</v-icon> 
                A besoin de {{ company.nbEmployeesByParcelle }} employé{{ company.nbEmployeesByParcelle>1?'s':'' }} /parcelle
              </v-card-subtitle>
              <v-card-subtitle class="pa-0 my-1">
                <v-icon small class="mb-1 mr-1">mdi-account-tie</v-icon> 
                {{ company.employees.length }} employé{{ company.employees.length>1?'s':'' }} actuellement
              </v-card-subtitle>
              <v-card-subtitle class="pa-0 my-1" :class="(nbEmployeeNeeded>0?'orange':'green')+'--text'">
                <v-icon small class="mb-1 mr-1"
                        :color="(nbEmployeeNeeded>0?'orange':'green')">
                        mdi-account-tie
                </v-icon> 
                {{ nbEmployeeNeeded }} poste{{ nbEmployeeNeeded>1?'s':'' }} à pourvoir
              </v-card-subtitle>

              <v-col cols="12" class="px-0 py-1">
                <v-btn  v-for="i in 1, company.employees.length" class="mx-1" 
                        x-small fab color="green" :title="company.employees[i-1].name"
                        :key="i" outlined @click="$root.$emit('openDialogBecomeSalary', company)">
                  <v-icon style="font-size:22px!important;" class="mb-1">mdi-account-tie</v-icon>
                </v-btn>
                <v-btn  v-for="i in 1, nbEmployeeNeeded" class="mx-1" 
                        x-small fab color="orange" title="afficher l'offre d'emplois"
                        :key="i+'x'" outlined @click="$root.$emit('openDialogBecomeSalary', company)">
                  <v-icon style="font-size:22px!important;" class="mb-1">mdi-account-off</v-icon>
                </v-btn>
              </v-col>
              
            </v-col>

            <v-col cols="12" sm="6" md="4" class="">
              <VehiclesTable :vehiclesList="company.vehicles" :isCompany="true"/>
              <v-card elevation="0" class="pa-3 mt-5 pt-5">
                <v-icon small class="">mdi-chevron-down</v-icon>
                Salaire du chef d'entreprise
                <v-row class="ma-0 mt-0">
                  <v-slider min="0" max="30" hide-details v-model="company.salaryBoss"></v-slider>
                  <v-chip color="blue" class="ml-3">
                    <b>{{ company.salaryBoss }} òto/mois</b>
                  </v-chip>
                </v-row>
              </v-card>
              <v-card elevation="0" class="pa-3 mt-3">
                <v-icon small class="">mdi-chevron-down</v-icon>
                Salaire des employés
                <v-row class="ma-0 mt-0">
                  <v-slider min="0" max="30" hide-details v-model="company.salaryEmployees"></v-slider>
                  <v-chip color="blue" class="ml-3">
                    <b>{{ company.salaryEmployees }} òto/mois</b>
                  </v-chip>
                </v-row>
              </v-card>
            </v-col>

            <v-col cols="12" sm="12" md="4" class="">
              <v-card outlined class="pa-0">
                <v-simple-table dense>
                  <template v-slot:default>
                    <thead>
                      <tr>
                        <th class="px-2 pl-4"><b>Valeurs /parcelle et /jour</b></th>
                        <th class="px-1"></th>
                      </tr>
                    </thead>
                    <tbody>
                        <tr>
                          <td>Valeur produite</td>
                          <td class="px-4 text-right">
                              <b>+{{ totalProductionValue }}</b> òto
                          </td>
                        </tr>
                        <tr>
                          <td>Coût de production</td>
                          <td class="px-4 text-right">
                              <b>-{{ totalCost }}</b> òto
                          </td>
                        </tr>
                        <tr>
                          <td>Salaires x{{ company.employees.length }}</td>
                          <td class="px-4 text-right">
                              <b>-{{ salaryCost }}</b> òto
                          </td>
                        </tr>
                        <tr>
                          <td>Salaires patron</td>
                          <td class="px-4 text-right">
                              <b>-{{ salaryBossByDay }}</b> òto
                          </td>
                        </tr>
                        <tr :class="(totalBenef >= 0 ? 'green' : 'red') + '--text'">
                          <td>Bénéfices</td>
                          <td class="px-4 text-right">
                              <b>{{ totalBenef }}</b> òto
                          </td>
                        </tr>
                    </tbody>
                  </template>
                </v-simple-table>
              </v-card>
              <v-card outlined class="mt-3 pa-0">
                <v-simple-table dense>
                  <template v-slot:default>
                    <thead class="">
                      <tr>
                        <th class="px-2 pl-4"><b>Valeurs /mois</b></th>
                        <th class="px-1"></th>
                      </tr>
                    </thead>
                    <tbody>
                        <tr :class="(totalBenef >= 0 ? 'green' : 'red') + '--text'" v-if="nbParcelles > 1">
                          <td class="py-1"><b>Bénéfices /mois</b><br>x1 parcelle</td>
                          <td class="px-4 text-right">
                              <b>{{ (totalBenef * 30.4375).toFixed(6) }}</b> òto
                          </td>
                        </tr>
                        <tr :class="(totalAllBenef >= 0 ? 'green' : 'red') + '--text'">
                          <td class="py-1"><b>Bénéfices total</b><br>x{{ nbParcelles }} parcelle{{ nbParcelles>1?'s':'' }}</td>
                          <td class="px-4 text-right">
                              <b>{{ (totalAllBenef * 30.4375).toFixed(6) }}</b> òto
                          </td>
                        </tr>
                    </tbody>
                  </template>
                </v-simple-table>
              </v-card>
            </v-col>


            <!-- PRODUCTIONS -->
            <v-col cols="12" sm="4" md="4" class="">
              <v-card outlined class="pa-3">
                <v-icon class="ml-2 mb-1">mdi-package-variant</v-icon> <b>En stock</b><br>
                <template v-if="company.productsIn.length == 0">
                  <v-icon small color="grey" class="ml-5">mdi-cancel</v-icon> Aucun
                </template>
                <template v-for="(product, key) in company.productsIn">
                    <v-chip outlined :key="key+'-out'"  
                          :color="product.iconColor"
                          :title="product.name"
                          class="ma-1 py-0 pl-3"
                          large style="height:40px;">
                      <v-icon :color="product.iconColor" class="mr-1">
                        mdi-{{ product.icon }}
                      </v-icon>
                        {{ parseInt(product.stock) }} 
                        {{ product.priceUnity }}{{ parseInt(product.stock) > 1 ? "s" : "" }}
                    </v-chip>
                </template>
              </v-card>
            </v-col>
            <v-col cols="12" sm="4" md="4" class="">
              <v-card outlined class="pa-3">
                <v-icon class="ml-2 mb-1">mdi-store</v-icon> <b>Disponible à la vente</b><br>
                <template v-if="company.productsOut.length == 0">
                  <v-icon small color="grey" class="ml-5">mdi-cancel</v-icon> Aucun
                </template>
                <template v-for="(product, key) in company.productsOut">
                    <v-chip outlined :key="key+'-out'"  
                          :color="product.iconColor"
                          :title="product.name"
                          class="ma-1 py-0 pl-2"
                          large style="height:40px;">
                      <v-icon :color="product.iconColor" class="mr-1">
                        mdi-{{ product.icon }}
                      </v-icon>
                        {{ parseInt(product.stock) }} 
                        {{ product.priceUnity }}{{ parseInt(product.stock) > 1 ? "s" : "" }}
                    </v-chip>
                </template>
              </v-card>
            </v-col>

            <!-- EMPRUNTS -->
            <v-col cols="12" sm="4" md="4" class="">
              <v-card outlined class="pa-3">
                <v-icon class="ml-2 mb-1">mdi-cash</v-icon> <b>Emprunts en cours</b><br>
                <template v-if="company.loans.length == 0">
                  <v-icon small color="grey" class="ml-5">mdi-cancel</v-icon> Aucun
                </template>

                  <v-simple-table style="background-color:transparent;">
                    <template v-slot:default>
                      <tbody>
                        <template v-for="(loan, key) in company.loans">
                          <tr :key="key">
                            <td class="py-2">
                              <b>
                                <v-icon x-small>mdi-circle</v-icon>
                                {{ loan.amountTotal }} òto 
                              · {{ loan.amountTotal / loan.nbMensuality }} òto/mois <br>
                              <small class="">{{ (loan.amountMensuality / 30.4375).toFixed(6) }} òto/jour</small>
                              </b>
                            </td>
                          </tr>
                        </template>
                      </tbody>
                    </template>
                  </v-simple-table>
                  <v-btn block elevation="0" @click="$root.$emit('openDialogLoan', company)">Faire un emprunt</v-btn>
              </v-card>
            </v-col>

            <!-- PRODUCTIONS -->
            <v-col cols="12" md="12" class="" v-if="company.productions.length > 0">
              <v-card outlined>
                <v-toolbar elevation="0" color="transparent" class="mb-0">
                  <v-icon small class="">mdi-chevron-down</v-icon>
                  Gérer la production
                  <v-btn outlined class="mx-5" color="grey" small @click="disableAllProduction">
                    <v-icon small>mdi-cancel</v-icon>
                    <span class="d-none d-sm-inline">Désactiver tout</span>
                  </v-btn>
                  <v-switch class="mt-5" label="détails" v-model="showProductionDetails"/>
                </v-toolbar>
                <!-- <v-divider/> -->
                <v-row class="ma-0">
                  <template v-for="(production) in company.productions">
                    <v-col :key="production.key" cols="12" md="6" :lg="company.productions.length > 3 ? '3' : '4'">
                      <v-card outlined color="background_dark" elevation="0">
                        <v-row class="ma-0 py-3 px-0">
                        <v-col cols="12" class="py-1 text-center" >
                            <v-icon large :color="production.productOut.iconColor">
                              mdi-{{ production.productOut.icon }}
                            </v-icon><br>
                            <b :class="production.productOut.iconColor+'--text'">{{ production.productOut.name }}</b><br>
                            <small>
                              {{ (production.stockOut / nbProductionActive * (nbEmployeesByParcelleCurrent * production.coefStockByEmployee)).toFixed(2) }} 
                              {{ production.productOut.priceUnity }} 
                              /parcelle /jour
                            </small>
                            <br>
                        </v-col>
                        <v-col cols="12" class="py-1 px-0" 
                               v-if="production.productsIn.length > 0">
                          <v-divider class="mt-3"/>
                          <v-simple-table class="" style="background-color:transparent!important;">
                            <template v-slot:default>
                              <thead>
                                <tr>
                                  <th class="px-2 pl-4"><b>Matériaux</b></th>
                                  <th class="px-1 text-center">Prix d'achat</th>
                                  <th class="px-1">Coût total</th>
                                </tr>
                              </thead>
                              <tbody>
                                <template v-for="(product) in production.productsIn">
                                  <tr :key="product._id" style="cursor:pointer">
                                    <td class="px-1 pl-3 text-left" >
                                        <v-icon small class="mr-1" :color="product.iconColor">
                                          mdi-{{ product.icon }}
                                        </v-icon>
                                        <span class="" :class="product.iconColor+'--text'">
                                          {{ product.name }}<br>
                                          <span class="ml-5">
                                            x{{ (production.stockIn[product.key] * (nbEmployeesByParcelleCurrent * production.coefStockByEmployee)).toFixed(2) }} 
                                            {{ product.priceUnity }}
                                          </span>
                                        </span>
                                    </td>
                                    <td class="px-1 text-left" >
                                      <v-text-field outlined rounded hide-details dense style="max-width:130px;"
                                                    :suffix="'òto'" class="input-text-right"
                                                    :rules="[v => /^[0-9]{1,}\.?[0-9]*$/gm.test(v)]"
                                                    title="modifier le prix d'achat en fonction du prix pratiqué par vos fournisseurs"
                                                    v-model="product.priceBuy">
                                      </v-text-field>
                                        <!-- {{ product.price }} òto/{{ product.priceUnity }} -->
                                    </td>
                                    <td class="px-1 text-left" >
                                        {{ (production.stockIn[product.key] * (nbEmployeesByParcelleCurrent * production.coefStockByEmployee) * product.priceBuy).toFixed(4) }} òto
                                    </td>
                                  </tr>
                                </template>
                              </tbody>
                            </template>
                          </v-simple-table>
                          <v-divider class="my-3"/>
                        </v-col>
                        <v-col cols="12" class="py-1 px-5">
                          <small>
                            Coûts de production total : 
                            <b>{{ productionCostTotal(production) }} òto</b>
                          </small><br>
                          <small>
                            Coûts de production /unité : 
                            <b>{{ productionCost(production) }} òto/{{ production.productOut.priceUnity}}</b>
                          </small>
                        </v-col>
                        <v-col cols="12" class="py-1 px-5" v-if="$vuetify.breakpoint.width > 600">
                          Prix de vente conseillé :
                          <small class="">
                          {{ productionCost(production) * 2 }} òto/{{ production.productOut.priceUnity}}
                          </small>
                        </v-col>
                        <v-col cols="12" class="py-1">
                          <v-text-field outlined rounded hide-details
                                        :suffix="'òto/'+production.productOut.priceUnity"
                                        prefix="Prix de vente :" 
                                        :rules="[v => /^[0-9]{1,}\.?[0-9]*$/gm.test(v)]"
                                        v-model="production.priceOut">
                          </v-text-field>
                        </v-col>
                        <v-col cols="12" class="py-1 px-0" v-if="productionCost(production) > 0">
                            <small style="margin-left:55px;">Marge : <b>{{ production.marge+'%' }}</b></small>
                            <v-slider hide-details v-model="production.marge" 
                                      @change="calculatePriceOutByMarge"
                                      :min="0" :max="300" step="1"
                                      class="mx-3" style=" margin-top:-10px;">
                              <template v-slot:prepend>
                                <v-icon @click="decrement(production)">mdi-minus</v-icon>
                              </template>

                              <template v-slot:append>
                                <v-icon @click="increment(production)">mdi-plus</v-icon>
                              </template>
                            </v-slider>
                        </v-col>
                        <v-col cols="12" class="py-1">
                          <StatsProduction :company="company" :showProductionDetails="showProductionDetails"
                                           :production="production" :productionsActive="productions"/>
                        </v-col>
                        <v-col cols="12" class="px-5 py-0 pb-1">
                          <v-checkbox v-model="productions" hide-details
                                      label="Activer la production" 
                                      class="mt-2" 
                                      :value="production._id"/>
                        </v-col>
                        </v-row>
                      </v-card>
                    </v-col>
                  </template>
                </v-row>

              </v-card>
            </v-col>

            <!-- PRODUCTS OUT -->
            <v-col cols="12" md="12" class="" v-if="company.category.key == 'commerce' || company.category.key == 'service'">

              <v-card outlined>
                <v-toolbar elevation="0" class="mb-3">
                  <v-icon small class="">mdi-chevron-down</v-icon>
                  Gérer les stocks
                  <v-spacer/>
                  <v-btn outlined class="mr-5" small @click="disableAllProductsOut">
                    <v-icon small>mdi-cancel</v-icon>
                    <span class="d-none d-sm-inline">Désactiver tout</span>
                  </v-btn>
                  <!-- <v-switch class="mt-5" label="détails" v-model="showProductionDetails"/> -->
                </v-toolbar>
                <!-- <v-divider/> -->
                <v-row class="ma-0">
                  <template v-for="(product) in company.productsOut">
                    <v-col :key="product._id" cols="12" md="6" 
                            :lg="company.productsOut.filter((prod)=>{ return prod.isStockable}).length > 3 ? '3' : '4'" 
                            v-if="product.isStockable">
                      <v-card outlined color="background_dark" elevation="0">
                        <v-row class="ma-0 py-3 px-0">
                        <v-col cols="12" class="py-1 text-center" >
                            <v-icon large :color="product.iconColor">
                              mdi-{{ product.icon }}
                            </v-icon><br>
                            <b :class="product.iconColor+'--text'">{{ product.name }}</b><br>
                            <!-- <small>
                              {{ (product.stock / nbProductionActive * (nbEmployeesByParcelleCurrent * production.coefStockByEmployee)).toFixed(2) }} 
                              {{ product.priceUnity }} 
                              /parcelle /jour
                            </small> -->
                        </v-col>
                        
                        <v-col cols="12" class="py-1">
                          <v-text-field outlined rounded hide-details style=""
                                        :suffix="'òto'" class="input-text-right"
                                        prefix="Prix d'achat :" 
                                        @change="calculatePriceProductByMarge"
                                        :rules="[v => /^[0-9]{1,}\.?[0-9]*$/gm.test(v)]"
                                        title="modifier le prix d'achat en fonction du prix pratiqué par vos fournisseurs"
                                        v-model="product.priceBuy">
                          </v-text-field>
                          <!-- <b>{{ productionCost(production) }} òto/{{ product.priceUnity}}</b> -->
                        </v-col>
                        <v-col cols="12" class="py-1 px-5" v-if="$vuetify.breakpoint.width > 600">
                          Prix de vente conseillé : 
                          <small class="">
                          {{ product.priceModel * 2 }} òto/{{ product.priceUnity}}
                          </small>
                        </v-col>
                        <v-col cols="12" class="py-1">
                          <v-text-field outlined rounded hide-details class="input-text-right"
                                        :suffix="'òto/'+product.priceUnity"
                                        prefix="Prix de vente :" 
                                        :rules="[v => /^[0-9]{1,}\.?[0-9]*$/gm.test(v)]"
                                        v-model="product.price">
                          </v-text-field>
                        </v-col>
                        <v-col cols="12" class="py-0 px-0 mt-2">
                          <small style="margin-left:65px;">Marge : <b>{{ product.marge+'%' }}</b></small>
                          <v-toolbar dense elevation="0" color="transparent" style="margin-top:-10px;"
                                      title="marge par rapport au prix d'achat">
                            <v-slider hide-details v-model="product.marge" 
                                      @change="calculatePriceProductByMarge"
                                      :min="0" :max="100" step="1"
                                      class="ml-2">
                              <template v-slot:prepend>
                                <v-icon @click="decrement(product)">mdi-minus</v-icon>
                              </template>

                              <template v-slot:append>
                                <v-icon @click="increment(product)">mdi-plus</v-icon>
                              </template>
                            </v-slider>
                          </v-toolbar>
                        </v-col>
                        <!-- <v-col cols="12" class="py-1">
                          <StatsProduction :company="company" :showProductionDetails="showProductionDetails"
                                           :production="production" :productionsActive="productions"/>
                        </v-col> -->
                        <v-col cols="12" class="px-5 py-0 pb-1">
                          <v-checkbox v-model="productsActive" hide-details
                                      label="Activer la vente" 
                                      class="mt-2" 
                                      :value="product._id"/>
                        </v-col>
                        </v-row>
                      </v-card>
                    </v-col>
                  </template>
                </v-row>

              </v-card>

            </v-col>
          </v-row>

    </v-card-text>

    <v-toolbar elevation="0">
      <v-spacer/>
      <v-btn elevation="0" class="mx-2" color="green" @click="save">
        <v-icon>mdi-content-save-outline</v-icon> Enregistrer
        <span class="d-none d-md-inline"> les changements</span>
      </v-btn>
    </v-toolbar>

  </v-card>
</v-dialog>
</template>
<style scoped>
    .input-text-right >>> input {
      text-align: right
    }
</style>

<style>
.chk-preselected{
  border-color: #c76127 !important;
}
.chk-selected{
  border-color: #4caf50 !important;
}
.table-revenus td{
  padding-right: 15px;
  width:50%;
}
</style>

<script>

import axios from 'axios'
import StatsProduction from '@/components/dashboard/StatsProduction.vue'
import Gravatar from 'vue-gravatar'
import VehiclesTable from '@/components/dashboard/VehiclesTable.vue'

export default {
  components: { StatsProduction, VehiclesTable, 'v-gravatar' : Gravatar },
  data: () => ({
    showDialog: false,
    company: null,
    margeMax: 300,
    showProductionDetails: false,
    
    productions: [],
    margeProductions: [],
    productsActive: []
  }),
  async mounted(){    
    this.$root.$off('openDialogManageCompany')
              .$on('openDialogManageCompany', (company) => { 
      this.showDialog = true
      this.company = company
      this.productions = []
      this.company.productions.forEach((production)=>{
        if(production.isActive) this.productions.push(production._id)
      })
      this.company.productsOut.forEach((product)=>{
        if(product.isActive) this.productsActive.push(product._id)
      })
    })
    this.$root.$off('closeDialogManageCompany')
              .$on('closeDialogManageCompany', () => { 
      this.showDialog = false
    })
  },
  methods: {
    save: async function(){
      let params = this.getSettingsParams()
      console.log("params", params)
      
      axios.post('/company/save-settings', params)
           .then((res)=>{
              if(res.data.error == false){
                console.log("SETTINGS SAVED WITH SUCCES", res.data)
                this.$store.dispatch('app/openSnackbar', 
                            { show: true, color:'green', 
                              title: "Paramètres enregistrés",
                              text: 'Les paramètres de votre entreprise ont été enregistrés',
                              icon: 'thumb-up' })
                this.$store.dispatch('auth/refreshUserData')
              }else{
                console.log("SETTINGS SAVE ERROR", res.data)
              }    
            })
    },
    getSettingsParams: function(){
      let params = {
        company: {
           _id: this.company._id,
          salaryBoss: this.company.salaryBoss,
          salaryEmployees: this.company.salaryEmployees,
        },
        productions: [],
        productsOut: []
      }
      this.company.productions.forEach((production)=>{
        //les prix d'achat des produits d'entrée
        let productsIn = []
        production.productsIn.forEach((product)=>{
          productsIn.push({ _id: product._id, priceBuy: product.priceBuy })
        })
        //paramètres de production
        params.productions.push({ _id: production._id,
                                  priceOut: production.priceOut,
                                  marge: production.marge,
                                  productsIn: productsIn,
                                  isActive: (this.productions.indexOf(production._id) > -1),
                                })
      })
      this.company.productsOut.forEach((product)=>{
        //paramètres de commerce
        let price = product.price
        this.company.productions.forEach((production)=>{
          if(production.productOut.key == product.key) price = production.priceOut
        })
        params.productsOut.push({ _id: product._id,
                                  price: price,
                                  priceBuy: product.priceBuy,
                                  marge: product.marge,
                                  isActive: (this.productsActive.indexOf(product._id) > -1),
                                })
      })
      return params 
    },
    productionCost: function(production){
     let cost = 0
     //(production.stockIn[product.key] * (nbEmployeesByParcelleCurrent * production.coefStockByEmployee)) * product.price
     production.productsIn.forEach((product)=>{
       cost += ((production.stockIn[product.key] ) / 
                (production.stockOut ) * product.priceBuy)
     }) 
     return cost.toFixed(4)
    },
    productionCostTotal: function(production){
     let cost = 0
     //(production.stockIn[product.key] * (nbEmployeesByParcelleCurrent * production.coefStockByEmployee)) * product.price
     production.productsIn.forEach((product)=>{
       cost += product.priceBuy * (production.stockIn[product.key] * 
                (this.nbEmployeesByParcelleCurrent * production.coefStockByEmployee))
     }) 
     return cost.toFixed(4)
    },
    decrement: function(production){
      if(production.marge > 0) production.marge -= 1
      this.calculatePriceOutByMarge()
      this.calculatePriceProductByMarge()
    },
    increment: function(production){
      if(production.marge < this.margeMax)  production.marge += 1
      this.calculatePriceOutByMarge()
      this.calculatePriceProductByMarge()
    },
    calculatePriceOutByMarge: function(){
      this.company.productions.forEach((production)=>{
        console.log("calculatePriceOutByMarge", this.margeProductions[production.name])
        if(this.productionCost(production) > 0)
        production.priceOut = (this.productionCost(production) * ((production.marge/100))).toFixed(4)
        //= ((production.priceOut - this.productionCost(production)) / production.priceOut * 100).toFixed(0)
        
      })
    },
    calculatePriceProductByMarge: function(){
      this.company.productsOut.forEach((product)=>{
        //console.log("calculatePriceProductByMarge", product.priceModel, product.price, product.marge)
        product.price = (product.priceBuy * (1+(product.marge/100))).toFixed(4)
      })
    },
    disableAllProduction: function(){
     this.productions = []
    },
    disableAllProductsOut: function(){
     this.productsActive = []
    }
  },
  computed: {
    nbProductionActive(){
      return this.productions.length > 0 ? this.productions.length : 1
    },
    nbParcelles(){
      let nb = this.company.surface / this.company.surfaceParcelle
      return nb > 0 ? nb : 1
    },
    nbEmployeesByParcelleCurrent: function(){
      if(this.company == null) return 0
      return ((this.company.employees.length+1) / (this.nbParcelles * this.company.nbEmployeesByParcelle))
    },
    totalProductionValue: function(){
      let total = 0
      this.company.productions.forEach((production)=>{
        let coeffEmployee = (this.nbEmployeesByParcelleCurrent * production.coefStockByEmployee)
        if(this.productions.indexOf(production._id) > -1){
          //console.log("totalProductionValue", production.name, production.stockOut, this.productions.length, coeffEmployee, production.priceOut)
          total += ((production.stockOut / this.productions.length * coeffEmployee * production.priceOut)) 
          }
      })
      return total.toFixed(4)
    }, 
    totalCost: function(){
      if(this.company == null) return 0
      let total = 0
      this.company.productions.forEach((production)=>{
        if(production.isActive)
        production.productsIn.forEach((product)=>{
          console.log("product", product.name)
            let coeffEmployee = (this.nbEmployeesByParcelleCurrent * production.coefStockByEmployee)
            total += (product.price * (production.stockIn[product.key] / this.productions.length * coeffEmployee)) 
        })
      })
      return total.toFixed(4)
    }, 
    salaryCost: function(){
      if(this.company == null) return 0
      return ((this.company.employees.length * this.company.salaryEmployees) / this.nbParcelles / 30.4375).toFixed(4)
    },
    salaryBossByDay(){
      return (this.company.salaryBoss/30.4375 / this.nbParcelles).toFixed(4)
    },
    totalBenef: function(){
      return (this.totalProductionValue - this.totalCost - this.salaryCost - this.salaryBossByDay).toFixed(4)
    },
    totalAllBenef: function(){
      return (this.totalBenef * this.nbParcelles).toFixed(4)
    },
    nbEmployeeNeeded(){
      if(this.company == null) return 0
      //console.log("nbEmployeeNeeded",  this.company.employees)
      //+1 pour le patron qui compte comme un employé
      return (this.company.surface / this.company.surfaceParcelle * this.company.nbEmployeesByParcelle) - (this.company.employees.length + 1)
    },
    
  },
  watch: {
      // '$route.path': { immediate: true, 
      //     async handler () {
      //       this.initData()
      //     }
      // },
  }, 
}
</script>
