const config = require('../config/' + process.env.NODE_ENV)

//import axios from "axios"
//import { i18n } from 'vue-lang-router';
// import store from '../store'

let core = {
    baseUrl: function(){
        return config.root_node.url + ":" + config.root_node.port + "/"
    },
    convertMonyUnity: function(amountMony, monyConvertValue){
        return amountMony * monyConvertValue
    },
    convertUnityMony: function(amountUnity, monyConvertValue){
        if(monyConvertValue == 0) return 0
        return amountUnity / monyConvertValue
    },
    getDistance :function(origin, destination) {
        // return distance in meters
        var lon1 = core.toRadian(origin[1]),
            lat1 = core.toRadian(origin[0]),
            lon2 = core.toRadian(destination[1]),
            lat2 = core.toRadian(destination[0]);
    
        var deltaLat = lat2 - lat1;
        var deltaLon = lon2 - lon1;
    
        var a = Math.pow(Math.sin(deltaLat/2), 2) + Math.cos(lat1) * Math.cos(lat2) * Math.pow(Math.sin(deltaLon/2), 2);
        var c = 2 * Math.asin(Math.sqrt(a));
        var EARTH_RADIUS = 6371;
        return (c * EARTH_RADIUS).toFixed(0)
    },
    toRadian: function(degree) {
        return degree*Math.PI/180;
    }
    // avatarUrl : function(entityId, refresh=true){
    //     if(entityId == null) return ''
    //     if(store.state.network == null) return
    //     if (core.rand == 0) core.initRand()
        
    //     let forceRefresh = ""
    //     let randate = new Date()
    //     randate = randate.toISOString().substr(0,13)
    //     if(refresh) forceRefresh = "?" + randate // Math.random()
    //     let url = "/uploads/avatar/" + entityId + ".png" + forceRefresh

    //     return url
    // },
}

export default core