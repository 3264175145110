
import axios from "axios"
// import { i18n } from 'vue-lang-router'

// const config = require('../../config/' + process.env.NODE_ENV)

const state = () => ({
  companyModels: [],

  companyFormModel: null,
  companyFormName: "",
});

const actions = {
  async fetchCompanyModels({ commit }){
    axios.post('/map/get-models-companies').then((res)=>{
      if (res.data.error === false)
        commit("setCompanyModels", res.data.entities)
      else
        console.log("error loading companyModels from Store", res.data)
    })
  },
  async saveFirstParamsForm({ commit }, params){
    commit("setCompanyFormModel", params.companyModel)
    commit("setCompanyFormName", params.companyName)
  },
  async saveCompany({ state }, params){
    let res = await axios.post('/company/create-company', params)
    if (res.data.error === true)
      console.log("succes saving company from Store", state.companyFormName, res.data)
      //commit("saveCompany", res.data.entities)
    else
      console.log("error saving company from Store", res.data)
  
    return res.data
  }
};

const mutations = {
  setCompanyModels(state, models) {
    state.companyModels = models
  },
  setCompanyFormModel(state, model) {
    state.companyFormModel = model
  },
  setCompanyFormName(state, name) {
    state.companyFormName = name
  },
};

const getters = {
}

export default {
  namespaced: true,
  getters,
  state,
  actions,
  mutations
};
