<template>
<v-simple-table style="width:100%;" >
  <thead>
    <tr>
      <th class="px-2 pl-4"><b>Nom</b></th>
      <th class="px-1">Distance /j</th>
      <th class="px-1">Chargement max</th>
      <th class="px-1" v-if="$vuetify.breakpoint.width > 600">Prix conseillé</th>
    </tr>
  </thead>
  <tbody>
    <template v-for="product in $store.state.app.catalog.products">
    <tr :key="product._id" v-if="product.category == 'vehicle'">
      <td :class="product.iconColor+'--text'">
        <v-icon class="mr-1" :color="product.iconColor">mdi-{{ product.icon }}</v-icon>
        <b>{{ product.name }}</b>
      </td>
      <td>
        <b>{{ product.kmByDay }} km</b>
      </td>
      <td>
        <b>{{ product.weightCapacity }} kg</b>
      </td>
      <td v-if="$vuetify.breakpoint.width > 600">{{ product.price }} òto/{{ product.priceUnity }}</td>
    </tr>
    </template>
  </tbody>
</v-simple-table>
</template>

<style></style>

<script>

//import axios from 'axios'
//import router from '../router/router'
//import core from '../plugins/core.js'

//import Post from '@/components/Post.vue'

export default {
  name: 'empty',
  components: {  },
  data: () => ({
    
    
  }),
  async mounted(){
    
  },
  methods: {
    

  },
  computed: {
    
    
  }
}
</script>
