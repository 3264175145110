<template>
<v-dialog  :fullscreen="$vuetify.breakpoint.width < 600" v-model="showDialog"  transition="dialog-bottom-transition"
            scrollable content-class="dialog-medium-bordered dialog-magik dialog-fullwidth">
  <v-card id="dialogDashboard">
    <v-toolbar elevation="0" max-height=60>
        <v-icon large class="ml-2 mr-4">mdi-view-dashboard-variant</v-icon> <b>Dashboard</b>
      <v-spacer/>
      <v-btn fab elevation="0" x-small @click="$store.dispatch('auth/refreshUserData')" 
            title="actualiser la quantité de stocks disponibles de chaque produit (il est possible que les stocks aient changés depuis le chargement des données)"
            class="mr-2">
        <v-icon small class="">mdi-refresh</v-icon>
      </v-btn>
      <v-btn @click="showDialog = false" 
              outlined icon small class="mr-0">
              <v-icon>mdi-close</v-icon>
      </v-btn>
    </v-toolbar>

    <v-card-text class="pt-5">
      <v-row>
        
        <v-col cols="12" sm="6" md="4">

          <v-card class="py-3 px-0" outlined>
            <v-list>
              <v-list-item>
                <v-list-item-avatar height="60" width="60">
                  <v-gravatar height="60" style=""
                        :default-img="'retro'" :email="$store.state.auth.user.name"/>
                </v-list-item-avatar>
                <v-list-item-content>
                  <h2>{{ $store.state.auth.user.name }}</h2>
                  <span><v-icon small>mdi-map-marker</v-icon> {{ $store.state.auth.user.city}}</span>
                </v-list-item-content>
                <v-chip style="height:40px;border-radius:50px;" color="secondary" to="/wallet"
                        class="font-righteous" @click="$root.$emit('closeDialogDashboard')">
                  <b class=" mr-1">{{ $store.getters['auth/amountTotalCurrent'].toFixed(2) }}</b> 
                  <small>{{ $store.state.app.monyUnity }}</small> 
                </v-chip>                
              </v-list-item>
            </v-list>

            <v-toolbar color="transparent" elevation="0">
              <v-progress-circular :rotate="-90" :size="60" :width="2"
                :value="$store.state.auth.user.scoreMain" style="font-size:14px;" class="font-righteous mr-2"
                :color="$store.state.auth.user.scoreMain > 70 ? 'green' : ($store.state.auth.user.scoreMain > 30) ? 'orange' : 'red'"
              >
              <b>{{ $store.state.auth.user.scoreMain }}%</b>
              </v-progress-circular>

            
              <template v-for="(score, keyS) in $store.state.auth.user.scores">
                <div :key="keyS" class="px-1 font-righteous" style="width:18%;" :title="'Score ' + score.name">

                  <small>
                    <v-icon small>mdi-{{ score.icon }}</v-icon> 
                    {{ score.score.toFixed(0) }}%
                  </small>
                  <v-progress-linear class="mt-2" 
                                    :value="score.score" 
                                    :color="score.score > 70 ? 'green' : (score.score > 30) ? 'orange' : 'red'">
                  </v-progress-linear>
                </div>
              </template>

            </v-toolbar>

          </v-card>

          <v-row class="pa-3">

            <v-card-title class="">
              <v-icon class="mr-1">mdi-chevron-down</v-icon>
              <v-icon  class="mb-1">mdi-account-tie</v-icon>
              Mon emplois
            </v-card-title>
            <!-- <v-divider class="ma-0"/> -->
            <template v-for="company in $store.state.auth.user.employeeIn">
              <v-col class="py-0 px-0" cols="12" :key="company._id">
                <CompanyCardSimple :company="company"/>
              </v-col>
            </template>
            <v-card-text v-if="$store.state.auth.user.employeeIn.length == 0">
              Vous n'êtes employé dans aucune entreprise
            </v-card-text>

            <v-card-title>
              <v-icon class="mr-1">mdi-chevron-down</v-icon>
              <v-icon class="mr-1">mdi-package-variant</v-icon>
              Mes entreprises
            </v-card-title>
            <template v-if="$store.state.auth.user.companies.length <= 2">
              <template v-for="company in $store.state.auth.user.companies">
                <v-col class="py-2 px-0" cols="12" :key="company._id">
                  <CompanyCard :company="company"/>
                </v-col>
              </template>
            </template>
            <v-card-text v-if="$store.state.auth.user.companies.length == 0">
              Vous n'êtes le propriétaire d'aucune entreprise.
              Vous pouvez créer et gérer jusqu'à 2 entreprises simultanément.
              <template v-if="$store.state.auth.user.employeeIn.length > 1">
                <i>Pour créer votre entreprise, vous devez d'abord quitter l'un de vos emplois salarié.
                  Il n'est pas possible de cumuler plus d'un emplois en plus d'une entreprise.
                </i>
              </template> 
            </v-card-text>
            <v-btn @click="showDialog = false" to="/create-company"
                    :disabled="!canCreateCompany"
                    block outlined color="secondary" 
                    class="mt-4">
              <v-icon class="mr-1">mdi-package-variant-plus</v-icon>
              Créer une entreprise
            </v-btn>
          </v-row>

        </v-col>

        
        <v-col cols="12" sm="6" md="4" class="pt-5 mt-2">

          <VehiclesTable :vehiclesList="$store.state.auth.user.vehicles" :isCompany="false"/>

          <v-card-title class="mt-0">
            <v-icon class="mr-1">mdi-chevron-down</v-icon>
            <v-icon  class="mb-1">mdi-newspaper-variant</v-icon>
            Mes commandes
          </v-card-title>
          <template v-for="commande in $store.state.auth.user.commandesIn">
            <v-card outlined :key="commande._id" v-if="commande.recurrent"
                    class="ma-1 mb-4" title="Commande récurrente (entrée de stock)"
                    style="border-style:dashed;">
              <v-card-text>
                <v-icon small class="mr-2">mdi-newspaper-variant</v-icon>
                <v-gravatar height="25" style="border-radius:50%; margin-bottom:-8px;"
                  :default-img="'retro'" :email="commande.sellerCompany.name" class="mr-2"/>
                <b>{{ commande.sellerCompany.name }}</b>
                <template v-if="commande.recurrent">
                    · Tous les {{ commande.recurrenceDays }} jour{{ commande.recurrenceDays>1?'s':'' }}
                </template>
              </v-card-text>

              <v-divider class=""/> 

              <v-card-text class="pb-0 pt-2">
                <template v-for="(product, key) in commande.basket">
                  <v-chip outlined :key="key+'-out'"  
                        :color="product.iconColor"
                        :title="product.name"
                        class="ma-1 py-0 pl-2" small>
                    <v-icon :color="product.iconColor" class="mr-1">
                      mdi-{{ product.icon }}
                    </v-icon>
                    {{ product.quantity > 1 ? product.quantity : product.quantity.toFixed(2) }} 
                    {{ product.priceUnity }}{{ product.quantity > 1 ? "s" : "" }}
                  </v-chip>
                </template>
              </v-card-text>
              <v-divider class="my-2"/>
              <!-- <v-card-title style="width:100%" class="text-right py-0">
              </v-card-title> -->
              <v-toolbar elevation="0" color="transparent" dense>
                <v-icon small class="mr-1">mdi-cart</v-icon>
                Total : <b class="pl-2">{{ totalPriceCommande(commande) }} òto</b>
                <small class="pa-2" style="margin-top:2px;"><i>~{{ monyToEuro(totalPriceCommande(commande)).toFixed(2) }} €</i></small>
                <v-spacer/>
                <v-btn icon small elevation="0" class="mr-2" 
                        @click="$root.$emit('openDialogShowCommande', commande)"
                        title="annuler cette commande">
                  <v-icon small>mdi-cancel</v-icon>
                </v-btn>
                <v-btn icon small elevation="0" class="mr-1" 
                        @click="$root.$emit('openDialogEntity', commande.sellerCompany, commande)"
                        title="modifier cette commande">
                  <v-icon small>mdi-pencil</v-icon>
                </v-btn>
              </v-toolbar>
            </v-card>
          </template>
          <template v-if="$store.state.auth.user.commandesIn.length == 0">
            <v-card-text>
              Vous n'avez aucune commande enregistrée.
              Vous pouvez passer des commandes auprès des entreprises,
              pour vous alimenter de façon régulière chez de vos fournisseurs préférés...
            </v-card-text>
          </template>
          
          <!-- EMPRUNTS -->
          <v-card-title class=" mt-2">
            <v-icon class="mr-1">mdi-chevron-down</v-icon>
            <v-icon  class="mb-1">mdi-cash</v-icon>
            Emprunts en cours
          </v-card-title>
          <v-card-text class="" v-if="$store.state.auth.user.loans.length == 0">
            Vous n'avez aucun emprunt en cours.
          </v-card-text>
          <v-btn block elevation="0" @click="$root.$emit('openDialogLoan', $store.state.auth.user)">Faire un emprunt</v-btn>

          <v-simple-table style="background-color:transparent;">
            <template v-slot:default>
              <tbody>
                <template v-for="(loan, key) in $store.state.auth.user.loans">
                  <tr :key="key">
                    <td class="py-2">
                      <b>
                        <v-icon x-small>mdi-circle</v-icon>
                        {{ loan.amountTotal }} òto 
                      · {{ loan.amountTotal / loan.nbMensuality }} òto/mois <br>
                      <small class="">{{ (loan.amountMensuality / 30.4375).toFixed(6) }} òto/jour</small>
                      </b>
                    </td>
                  </tr>
                </template>
              </tbody>
            </template>
          </v-simple-table>
          

        </v-col>


        <v-col cols="12" sm="6" md="4">
          

          <!-- <v-card-title class="mt-5">
            <v-icon class="mr-1">mdi-chevron-down</v-icon>
            <v-icon  class="mb-1">mdi-home-modern</v-icon>
            <template v-if="$store.state.auth.user.houses.length < 2">Mon logement</template>
            <template v-else>Mes logements</template>
          </v-card-title> -->

          <v-col cols="12" class="px-0 pt-1">
            <v-row>
              <template v-for="(house) in $store.state.auth.user.houses">
                <v-col :key="house._id" cols="12" md="12" lg="12">
                  <HouseCard :house="house"/>
                </v-col>
              </template>
            </v-row>
          </v-col>

          <v-card outlined class="my-4">
            <v-col cols="12">
              <v-icon small class="mb-1">mdi-comment-alert</v-icon>
              Conseils
            </v-col>
            <v-divider class="mb-2"/>
            
          </v-card>
          <v-card outlined class="my-4">
            <v-col cols="12">
              <v-icon small class="mb-1">mdi-bell</v-icon>
              Notifications
            </v-col>
            <v-divider class="mb-2"/>
            
          </v-card>
        </v-col>

        <v-col cols="12">
          <v-row>
            <template v-if="$store.state.auth.user.companies.length > 2">
              <template v-for="company in $store.state.auth.user.companies">
                <v-col class="py-2 px-2" cols="12" sm="4" md="3" :key="company._id">
                  <CompanyCard :company="company"/>
                </v-col>
              </template>
            </template>
          </v-row>
        </v-col>
      </v-row>
    </v-card-text>

  </v-card>

  <DialogShowCommande/>
  <DialogWallet/>
  <DialogManageCompany/>

</v-dialog>
</template>

<style>
  .chip-title-dialog{
    height: 60px !important;
    margin-bottom: 0px;
    margin-top: -3px;
    margin-left: -15px;
    border-radius: 0px !important;
    font-size: 20px !important;
  }
</style>

<script>

//import axios from 'axios'
//import router from '../router/router'
//import core from '../plugins/core.js'

import CompanyCard from '@/components/dashboard/CompanyCard.vue'
import CompanyCardSimple from '@/components/dashboard/CompanyCardSimple.vue'

import DialogShowCommande from '@/components/dashboard/DialogShowCommande.vue'
import DialogWallet from '@/components/dashboard/DialogWallet.vue'
import DialogManageCompany from '@/components/dashboard/DialogManageCompany.vue'
import VehiclesTable from '@/components/dashboard/VehiclesTable.vue'
import HouseCard from '@/components/entity/HouseCard.vue'

import Gravatar from 'vue-gravatar'

const config = require('../../config/' + process.env.NODE_ENV)

export default {
  name: 'empty',
  components: { CompanyCard, CompanyCardSimple, 
                DialogShowCommande, DialogWallet, DialogManageCompany, 
                VehiclesTable, 'v-gravatar' : Gravatar, HouseCard },
  data: () => ({
    showDialog: false,
    productConsume: null,
    showDialogConsume: false
  }),
  async mounted(){    
    this.$root.$off('openDialogDashboard')
              .$on('openDialogDashboard', () => { 
      this.showDialog = true
    })
    this.$root.$off('closeDialogDashboard')
              .$on('closeDialogDashboard', () => { 
      this.showDialog = false
    })
  },
  methods: {
    totalPriceCommande(commande){
      let total = 0
      commande.basket.forEach((product)=>{
        total += product.price * product.quantity
      })
      return total.toFixed(4)
    },
    monyToEuro(amount){ return amount * config.monyToEuro },
  },
  computed: {
    canCreateCompany(){
      return this.$store.state.auth.user.employeeIn.length <= 1
          && this.$store.state.auth.user.companies.length <= 1
    },
    
  }
}
</script>
