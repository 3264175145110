<template>
<v-dialog  :fullscreen="$vuetify.breakpoint.width < 600"
            v-model="showDialog" max-width="1200" transition="dialog-bottom-transition"
            scrollable content-class="dialog-medium-bordered dialog-magik"
  >
  <v-card id="dialogWallet">
    <v-toolbar elevation="0" max-height="60" id="tab-catalog">
      <v-tabs v-model="tab" background-color=""
                color="blue" grow >
        <v-tab><v-icon class="mr-1">mdi-book-outline</v-icon> <span class="d-none d-sm-inline">Produits</span></v-tab>
        <v-tab><v-icon class="mr-1">mdi-factory</v-icon> <span class="d-none d-sm-inline">Entreprises</span></v-tab>
        <v-tab><v-icon class="mr-1">mdi-home-modern</v-icon> <span class="d-none d-sm-inline">Logements</span></v-tab>
        <v-tab><v-icon class="mr-1">mdi-road-variant</v-icon> <span class="d-none d-sm-inline">Véhicules</span></v-tab>
      </v-tabs>
      <v-spacer/>
      <v-btn fab elevation="0" x-small @click="loadCatalog" 
            title="actualiser le catalogue"
            class="mr-2">
        <v-icon small class="">mdi-refresh</v-icon>
      </v-btn>
      <v-btn @click="showDialog = false" 
              outlined icon small class="mr-0">
              <v-icon>mdi-close</v-icon>
      </v-btn>
    </v-toolbar>

    <v-card-text style="max-height:700px;" class="pa-0" v-if="$store.state.app.catalog != null">
      <v-col cols="12" class="pa-0">
        
        <v-tabs-items style="min-height:700px;" v-model="tab">
          <v-tab-item>
            <v-card color="" flat>
              <v-card-title class="d-sm-none">Catalogue des produits</v-card-title>
              <TableProduct/>
            </v-card>
          </v-tab-item>
          <v-tab-item>
            <v-card color="" flat>
              <v-card-title class="d-sm-none">Catalogue des entreprises</v-card-title>
              <TableCompanies/>
            </v-card>
          </v-tab-item>
          <v-tab-item>
            <v-card color="" flat>
              <v-card-title class="d-sm-none">Catalogue des logements</v-card-title>
              <TableHouses/>
            </v-card>
          </v-tab-item>
          <v-tab-item>
            <v-card color="" flat>
              <v-card-title class="d-sm-none">Catalogue des véhicules</v-card-title>
              <TableVehicles/>
            </v-card>
          </v-tab-item>
        </v-tabs-items>
      </v-col>
    </v-card-text>
  </v-card>
</v-dialog>
</template>

<style scoped>
  #tab-catalog .v-tab{
    min-width: 50px;
  }
</style>

<script>

import TableProduct from '@/components/catalog/TableProduct.vue'
import TableCompanies from '@/components/catalog/TableCompanies.vue'
import TableHouses from '@/components/catalog/TableHouses.vue'
import TableVehicles from '@/components/catalog/TableVehicles.vue'

export default {
  components: { TableProduct, TableCompanies, TableHouses, TableVehicles },
  data: () => ({
    showDialog: false,
    company: null,
    tab: 0
  }),
  async mounted(){    
    this.$root.$off('openDialogCatalog')
              .$on('openDialogCatalog', () => { 
      this.showDialog = true
    })
    this.$root.$off('closeDialogCatalog')
              .$on('closeDialogCatalog', () => { 
      this.showDialog = false
    })

    this.loadCatalog()
  },
  methods: {
    loadCatalog(){
      this.$store.dispatch("app/loadCatalog")
    }

  },
  computed: {
    
    
  }
}
</script>
