<template>
<v-dialog  :fullscreen="$vuetify.breakpoint.width < 600"
            v-model="showDialog" max-width="600"
            scrollable
  >
  <v-card id="dialogCaptcha" v-if="commande != null">
    <v-toolbar elevation="1" max-height="60">
      <v-icon  class="mr-1">mdi-newspaper-variant-outline</v-icon> <b>Commande n°{{ commande._id.substr(commande._id.length-10, 10) }}</b>
      <v-spacer/>

      <v-btn @click="showDialog = false" 
              outlined icon small class="mr-0">
              <v-icon>mdi-close</v-icon>
      </v-btn>
    </v-toolbar>

    <v-card-text class="pa-0">
        
        <v-row class="mx-0 my-0">
          <v-col cols="12" sm="12" md="12" class="py-0">
            
            <v-list class="py-0">
              <v-list-item class="px-0">
                
                  <v-list-item-avatar height="46" width="46" :class="'mr-3'">
                    <v-gravatar height="30" style="border-radius:50%; margin-bottom:-6px;"
                                :default-img="'retro'" :email="entityBuyer.name"/>
                  </v-list-item-avatar>
                  
                  <v-list-item-content>

                    <v-list-item-subtitle class="mb-1" style="">
                      <v-icon small>mdi-chevron-down</v-icon> Client
                    </v-list-item-subtitle>
                    <v-list-item-title class="headline mb-1" style="line-height: 1.3rem;">
                      <span style="text-transform:none;font-size:0.7em!important;text-decoration:none;white-space:normal;display:inline;" 
                        :class="'pa-0'">
                        <b>{{ entityBuyer.name }}</b>
                      </span>
                    </v-list-item-title>
                    
                    <v-list-item-subtitle v-show="entityBuyer.address!=null ||  entityBuyer.city!=null">
                      <v-icon small style="margin-bottom:3px;margin-right:-1px;" v-if="entityBuyer.city != ''">mdi-map-marker</v-icon>
                      {{ entityBuyer.address }} {{ entityBuyer.city }}
                    </v-list-item-subtitle>

                    <v-list-item-subtitle v-if="entityBuyer.category!=null">
                      <span :class="entityBuyer.category.iconFaColor+'--text'">{{ entityBuyer.category.name }}</span> - 
                      <b class="pr-2">{{ entityBuyer.ownModel.name }}</b>
                    </v-list-item-subtitle>

                  </v-list-item-content>
              </v-list-item>
            </v-list>
                  
            <div class="flex orange--text mt-3" v-if="!$store.state.auth.isLogin">
              <v-btn  outlined to="/login" class="orange--text">
                <v-icon small>mdi-login</v-icon> Se connecter
              </v-btn><br>
              Connectez-vous pour interagir avec cette entité.
            </div>

          </v-col>

          <v-col cols="12" sm="12" class="pt-0">
            <v-btn  small color="blue lighten-1" class="mr-2 mt-2" outlined 
                    v-if="$store.state.auth.user._id == commande.buyerUser._id"
                    :to="'/private/'+commande.sellerCompany.userOwner"
                    @click="$root.$emit('closeDialogShowCommande')"
                    title="contacter le client">
              <v-icon small color="" class="pr-1">mdi-comment-account-outline</v-icon>
              Contacter <span class="d-none d-sm-inline">le propriétaire</span>
            </v-btn>
            <v-chip class="mt-2" color="" outlined>
              Récurrence : <b class="ml-1">{{ commande.recurrenceDays }} jour{{ commande.recurrenceDays>1?'s':'' }}</b>
            </v-chip>
          </v-col>

          <v-simple-table style="width:100%">
             <thead>
              <tr>
                <th class="text-right">Produit</th>
                <th class="text-right">Quantité</th>
                <th class="text-right">Prix à l'unité</th>
                <th class="text-right">Prix total</th>
              </tr>
            </thead>
             <tbody>
              <template v-for="(product, key) in commande.basket">
                <tr :key="key">
                  <td class="text-left" :class="product.iconColor+'--text'">
                    <v-icon  :color="product.iconColor">mdi-{{product.icon }}</v-icon>
                    {{ product.name }}
                  </td>
                  <td class="text-right">{{ product.quantity }} {{ product.priceUnity }}</td>
                  <td class="text-right">{{ product.price }} òto</td>
                  <td class="text-right">{{ (product.price * product.quantity).toFixed(4) }} òto</td>
                </tr>
              </template>
              <tr>
                  <td class="text-right">-</td>
                  <td class="text-right">-</td>
                  <td class="text-right"><b>Total</b></td>
                  <td class="text-right"><b>{{ totalCommande }} òto</b></td>
                </tr>
            </tbody>
          </v-simple-table>

      </v-row>
    </v-card-text>

    <v-toolbar max-height="60">
      <v-spacer/>
      <v-btn outlined><v-icon>mdi-check</v-icon> Ok</v-btn>
    </v-toolbar>
  </v-card>
</v-dialog>
</template>

<style></style>

<script>

//import axios from 'axios'
//import router from '../router/router'
//import core from '../plugins/core.js'

//import Post from '@/components/Post.vue'
import Gravatar from 'vue-gravatar'

export default {
  name: 'dialogshowcommande',
  components: { 'v-gravatar' : Gravatar },
  data: () => ({
    showDialog: false,
    commande: null
  }),
  async mounted(){    
    this.$root.$off('openDialogShowCommande')
              .$on('openDialogShowCommande', (commande) => { 
      this.commande = commande
      this.showDialog = true
    })
    this.$root.$off('closeDialogShowCommande')
              .$on('closeDialogShowCommande', () => { 
      this.showDialog = false
      this.commande = null
    })
  },
  methods: {
    nl2br: function(str, is_xhtml){
      var breakTag = (is_xhtml || typeof is_xhtml === 'undefined') ? '<br ' + '/>' : '<br>'
      let newStr = (str + '').replace(/([^>\r\n]?)(\r\n|\n\r|\r|\n)/g, '$1' + breakTag + '$2')
      //let maxLength = 150
      // let p = newStr.length > maxLength ? '...' : ''
      // newStr = newStr.substr(0, maxLength) + p
      return newStr
    },

  },
  computed: {
    entityBuyer(){
      console.log('this.commande', this.commande)
      if(this.commande == null) return null
      if(this.commande.buyerCompany != null) return this.commande.buyerCompany
      else return this.commande.buyerUser
    },
    totalCommande(){
      let total = 0
      this.commande.basket.forEach(product => {
        total += product.price * product.quantity
      })
      return total
    }
  }
}
</script>
