<template>
  <v-card v-show="$store.state.app.showDialogConv" v-if="conversation != null" 
          class="dialog-conv"
          :class="inDialog ? 'in-dialog' : 'in-window'"
          :height="$vuetify.breakpoint.height-285" 
          :min-width="300" :width="500" 
          :max-width="$vuetify.breakpoint.width > 960 ? $vuetify.breakpoint.width * 0.5 : $vuetify.breakpoint.width" elevation="0">

    <v-toolbar color="blue darken-2" elevation="0" dense max-height="50" class="pt-0">
      <v-icon  class="mr-1 mt-1">mdi-comment-outline</v-icon> 
      <v-gravatar height="25" class="mx-1" style="border-radius:50%;margin-bottom:0px;"
                      :default-img="'retro'" :email="userDest.name"/>
      <b class="mx-1">{{ userDest.name }}</b>
        <template v-if="conversation.company != null">
          , propriétaire de <b>{{ conversation.company.name }}</b>
        </template>
      <v-spacer/>
      <v-btn fab elevation="0" color="blue" x-small @click="showDialogCloseConv = true" 
            title="bloquer cette personne, pour ne plus recevoir de message"
            class="mr-2">
        <v-icon small class="">mdi-cancel</v-icon>
      </v-btn>
      <v-btn fab elevation="0" color="blue" x-small @click="showDialogCloseConv = true" 
            title="effacer cette conversation"
            class="mr-2">
        <v-icon small class="">mdi-delete</v-icon>
      </v-btn>
      <v-btn fab elevation="0" color="blue" x-small @click="$store.dispatch('auth/refreshUserData')" 
            title="actualiser les données"
            class="mr-2">
        <v-icon small class="">mdi-refresh</v-icon>
      </v-btn>
      <v-btn @click="$store.dispatch('app/setShowDialogConv', { show:false })" fab elevation="0" color="blue"
               x-small class="mr-0">
              <v-icon>mdi-close</v-icon>
      </v-btn>
    </v-toolbar>

    <v-card-text id="conv-text-scroll" :style="'height:'+($vuetify.breakpoint.height-455)+'px;'" style="overflow:auto;">

      <v-col cols="12" class="mx-auto px-0" :refresh="refresh">

        <template v-for="(message, m) in conversation.messages">
          <v-card :key="m" v-if="message.senderName != null" 
                  :class="lastDate < message.created ? 'newmsg' : ''" 
                  class="mt-1 private-msg" elevation="0" color="transparent">
            <v-col cols="12" class="py-2">
              <v-gravatar height="25" class="mr-2" style="border-radius:50%;margin-bottom:-5px;"
                              :default-img="'retro'" :email="message.senderName"/>

              <b>{{ message.senderName }}</b> 

              <small class="mx-1 grey--text">•</small>

              <time-ago :datetime="message.updated" style="font-size:11px !important;"
                        :refresh="60" long :locale="$vuetify.lang.current">
              </time-ago>

              <!-- <template v-if='lastDate < message.created'>Not read !</template> -->

              <template v-if="(editMsgInx == null || editMsgInx != m)">

                <template v-if="message.senderId == $store.state.auth.user._id">
                  <v-menu offset-y>
                    <template v-slot:activator="{ on, attrs }">
                      <v-btn small class="ml-1" icon v-bind="attrs" v-on="on">
                        <v-icon small>mdi-chevron-down</v-icon>
                      </v-btn>
                    </template>
                    <v-list>
                      
                      <v-list-item dense title="modifier ce message" @click="editMsgInx = m">
                        <v-icon small>mdi-pencil</v-icon>
                        <v-list-item-title>Modifier</v-list-item-title>
                      </v-list-item>
                      <v-list-item dense title="supprimer ce message" @click="deleteMsgInx = m">
                        <v-icon small>mdi-delete</v-icon>
                        <v-list-item-title>Supprimer</v-list-item-title>
                      </v-list-item>
                      
                    </v-list>
                  </v-menu>
                </template>


                <div v-if="deleteMsgInx != null && deleteMsgInx == m" class="">
                  <v-toolbar dense background-color="transparent" elevation="0" outlined class="mt-2 px-0 red--text">
                    <small><b>Supprimer ce message ?</b></small>
                    <v-spacer></v-spacer>
                    <v-btn color="" elevation="0" class="mr-1" small @click="deleteMsgInx = null">annuler</v-btn>
                    <v-btn color="red" elevation="0" class="ml-1" dark small @click="deleteMessage(m)">supprimer</v-btn>
                  </v-toolbar>
                </div>

                <br>

                <p class="pl-5 ml-3 mb-0" v-html="nl2br(message.message)"></p>
              </template>

            </v-col>
          </v-card>
          
          
          <div :key="m+'txtarea'" v-if="editMsgInx != null && editMsgInx == m">
            <v-textarea outlined label="Modifier votre message..." v-model="conversation.messages[editMsgInx].message" 
                        @keyup.enter.exact="saveEditMessage()" rows="3" :loading="sending"
                        @click:append="saveEditMessage()" class="mt-2"
                        append-icon="mdi-check" 
                        auto-grow hide-details>
            </v-textarea>
            <v-toolbar dense elevation="0"  class="mb-5 py-0">
              <v-spacer></v-spacer>
              <v-btn color="" elevation="0" class="mx-1" small @click="editMsgInx = null">annuler</v-btn>
              <v-btn color="green" elevation="0" class="mx-1" dark small @click="saveEditMessage">enregistrer</v-btn>
            </v-toolbar>
          </div>

        </template>
      </v-col>

    </v-card-text>

    <div class="pa-3">
        <v-textarea outlined label="Votre message..." v-model="txtMsg" 
                    @keyup.enter.exact="sendMessage()" rows="3"
                    @click:append="sendMessage()" hide-details
                    append-icon="mdi-send" :loading="sending"
                    >
        </v-textarea>
    </div>

    <v-dialog v-model="showDialogCloseConv" max-width="600">
      <v-card>
        <v-card-title class="red--text">
          <v-icon color="red" class="mr-2">mdi-delete</v-icon> 
          Supprimer cette conversation ?
        </v-card-title>

        <v-card-text class="red--text text--lighten-2">
          <b>Voulez-vous vraiment supprimer cette conversation ?</b><br>
          L'ensemble des messages échangés avec <b>{{ userDest.name }}</b> seront supprimés.
        </v-card-text>
        

        <v-card-actions class="pa-3">
          <v-spacer></v-spacer>
          <v-btn color="" text @click="showDialogCloseConv=false" >
            <v-icon small>mdi-close</v-icon> Annuler
          </v-btn>
          <v-btn color="red" dark @click="closeConversation()" >
            <v-icon small>mdi-delete</v-icon> Oui, supprimer
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

  </v-card>
</template>

<style>
  .in-window.dialog-conv{
    position:fixed;
    margin:0px !important;
    bottom:72px;
    right:56px;
    z-index:1;
    box-shadow: none !important
  }
  .in-dialog.dialog-conv,
  .in-dialog.dialog-conv.v-card,
  .in-window.dialog-conv,
  .in-window.dialog-conv.v-card{
    border-radius:0px 0 0 0;
  }

  .theme--dark .in-window.dialog-conv.v-card{
    background-color: rgba(18, 18, 18, 0.95);
  }


  @media (max-width: 960px) {
    .dialog-magik.v-dialog{
      left: 0%;
      top:55px;
    }
  }

</style>

<script>

import axios from 'axios'
//import router from '../router/router'
import core from '../../plugins/core.js'
import {TimeAgo} from "vue2-timeago"

//import Post from '@/components/Post.vue'
//const config = require('../../config/' + process.env.NODE_ENV)

import Gravatar from 'vue-gravatar'

export default {
  name: 'DialogConv',
  props: ['inDialog'],
  components: { 'v-gravatar' : Gravatar, TimeAgo },
  data: () => ({
    showDialog: false,
    inited: false,
    conversation: null,
    txtMsg: "",
    sending: false,
    sendingEdit: false,
    sendingDelete: false,
    editMsgInx: null,
    deleteMsgInx: null,
    refresh: new Date(),
    lastDate: new Date(),
    showDialogCloseConv: false,
    showDialogProposal: false
  }),
  async mounted(){
    this.$store.dispatch('app/incStat', '/private')

    this.$root.$off('openDialogConv')
              .$on('openDialogConv', (conversation) => { 
      this.conversation = conversation
      this.showDialog = true
      this.$store.dispatch('app/setShowDialogConv', { show: true, conv: this.conversation })
      this.scrollBottom()
    })

    this.$root.$off('closeDialogConv')
              .$on('closeDialogConv', () => { 
      this.showDialog = false
      this.$store.dispatch('app/setShowDialogConv', { show: false })
    })
    
    this.$root.$on('hot-private-message', (newMsg) => { 
      console.log('#WS hot-private-message', newMsg)
      this.conversation.messages.push(newMsg)
      this.$store.dispatch("auth/readConv", this.conversation)
      this.scrollBottom()
    })
    this.$root.$on('hot-edit-private-message', (data) => { 
      console.log('#WS hot-edit-private-message', data)
      this.conversation.messages[data.inx] = data.newMsg
      this.refresh = new Date()
    })
    this.$root.$on('hot-delete-private-message', (inx) => { 
      console.log('#WS hot-delete-private-message', inx)
      this.conversation.messages.splice(inx, 1)
      this.refresh = new Date()
    })

    //this.initData()
  },
  methods: {
    async initData(){
      // console.log("inited", this.inited)
      // if(this.inited) return

      // this.inited = true
      // let route = this.$route.params.userId + (this.$route.params.companyId ? '/'+this.$route.params.companyId : '')
      // const { data } = await axios.get('/private/get-conversation/'+ route)
      // if(!data.error){
      // console.log("/private/get-conversation/ data", data)
      //   this.conversation = data.conversation
      //   this.$store.dispatch('app/setCurrentPrivateConvUserName', this.userDest.name)
      //   this.$store.dispatch("auth/setReadConv", this.conversation)
      //   this.lastDate = data.lastDate
      //   this.scrollBottom()
      // }else{
      //   console.log("error get-offer", data)
      // }
    },
    async sendMessage(){
      if(this.txtMsg == "" || this.sending) return 

      const params = { message: this.txtMsg }
      this.txtMsg = ""
      this.sending = true
      const { data } = await axios.post('/private/send-message/'+ this.conversation._id, params)
      this.sending = false

      if(!data.error){
        await this.$store.dispatch("auth/updateReadConv", this.conversation._id)
        this.conversation.messages.push(data.message)     
        this.lastDate = new Date()
        this.scrollBottom()
      }else{
        console.log("error get-offer", data)
      }
    },
    async saveEditMessage(){
      const params = { inx: this.editMsgInx, 
                       message: this.conversation.messages[this.editMsgInx].message }

      this.sendingEdit = true
      const { data } = await axios.post('/private/edit-message/'+ this.conversation._id, params)
      this.sendingEdit = false

      if(!data.error){ this.editMsgInx = null }
      else{ console.log("error edit-message", data) }
    },
    async deleteMessage(inx){
      this.sendingDelete = true
      const { data } = await axios.post('/private/delete-message/'+ this.conversation._id, { inx: inx })
      this.sendingDelete = false
      
      if(!data.error){ 
        this.deleteMsgInx = null
        this.conversation.messages.splice(inx, 1)
        this.refresh = new Date()
      }else{ console.log("error delete-message", data) }
    },
    closeConversation: async function(){
      this.sending = true
      const { data } = await axios.post('/private/close-conversation/'+ this.conversation._id)
      this.sending = false
      if(!data.error){
        await this.$store.dispatch("auth/refreshUserData")
        this.$router.push('/wallet')
      }else{
        console.log("error get-offer", data)
      }
    },
    scrollBottom: function(){
      setTimeout(() => {
        //console.log("scrollHeight", document.getElementById('conv-text-scroll').scrollHeight)
        let el = document.getElementById('conv-text-scroll')
        if(el){
          el.scrollTop = el.scrollHeight
        }
      }, 300)
    },
    nl2br: function(str, is_xhtml){
      var breakTag = (is_xhtml || typeof is_xhtml === 'undefined') ? '<br ' + '/>' : '<br>'
      let newStr = (str + '').replace(/([^>\r\n]?)(\r\n|\n\r|\r|\n)/g, '$1' + breakTag + '$2')
      return newStr
    },
    baseUrl(){ return core.baseUrl() },
    
  },
  
  computed: {
    userDest(){ 
      //console.log("conversation", this.conversation.user1._id, this.conversation.user2)
      if(this.conversation == null) return {} 
      return (this.conversation.user1._id == this.$store.state.auth.user._id) ? this.conversation.user2 : this.conversation.user1 
    },
    canSendProposal(){
      if(!this.$store.state.auth.isLogin) return false

      let canSend = true
      let props = this.$store.state.auth.user.propositionsSent
      props.forEach((prop) => {
        if(prop.offer == this.conversation.offer._id){
          if(props.nego[props.nego.length - 1].status != "REFUSED") canSend = false
        }
      })
      return canSend && (this.$store.state.auth.user._id != this.conversation.offer.creator._id)
    },
    propositionNotRefused(){
      let props = this.$store.state.auth.user.propositionsSent
      let thisProp = null
      props.forEach((prop)=>{
        console.log("prop", this.conversation.offer._id, prop)
        if(prop.offer._id == this.conversation.offer._id) 
          thisProp = props
      })
      if(thisProp == null) return true
      if(thisProp.negos != null) return thisProp.negos[thisProp.negos.length-1].status != 'REFUSED'
      else return true
    },
    hasSentProposal(){
      let sent = false
      let props = this.$store.state.auth.user.propositionsSent
      props.forEach((prop) => {
        if(prop.offer._id == this.conversation.offer._id) sent = true
      })
      return sent
    },
  },
  watch:{
    '$store.state.app.currentConv._id': { immediate: true, 
        async handler () {
          console.log("$store.state.app.currentConv._id", this.$store.state.app.currentConv)
            this.conversation = this.$store.state.app.currentConv
            this.scrollBottom()
            if(this.conversation != null)
              this.$store.dispatch("auth/readConv", this.conversation)
        }
    },
  }
}
</script>
