
import axios from "axios"
//import { io } from "socket.io-client";
//import { i18n } from 'vue-lang-router';

const state = () => ({
  
  //stock les résultat de la recherche de /map/search
  results: {
    users: {
      countRes: 0,
      entities: []
    },
    companies: {
      countRes: 0,
      entities: []
    }
  },
  //transforme les results en une seule et même array avec tous les types d'entités dedans
  entities: [],
  entityTypeSelected: [],

  requestParams: {
    //userType: "USER",
    search: "",
    radius: 30000,
    coordinates: null,
    categoryId: null,
    fetchLimit: 50,
    skip: 0
  },

  langAvailables : [],

  streamLoading: false,
  isEndOfStream: false,

  countResultsTotal: 0,
  countResultsTotalLastPart: 0,
  
  dataTypeConfigDefault: {
    "user":    { color: "red", icon: "user" },
    "company": { color: "blue", icon: "certificate" },
  },

  transportersKeys: {
    'myself'   : ['feet', 'bicycle', 'bicyclecargo', 'horse', 'car'],
    'delivery' : ['bicyclecargo', 'horse', 'truck']
  },
  transporters: {
    'myself' : {
      'feet'    :       { name: 'À pieds',     icon: 'run-fast',             priceKm: '0',       speedKm: 5 , maxDistance: 5    , maxWeight: 5 },
      'bicycle' :       { name: 'Vélo',        icon: 'bicycle-basket',       priceKm: '0',       speedKm: 15, maxDistance: 10   , maxWeight: 10 },
      'bicyclecargo' :  { name: 'Vélo cargo',  icon: 'bicycle-cargo',        priceKm: '0',       speedKm: 10, maxDistance: 10   , maxWeight: 50 },
      'horse'   :       { name: 'Cheval',      icon: 'horse-human',          priceKm: '0',       speedKm: 15, maxDistance: 20   , maxWeight: 500 },
      'car'   :         { name: 'Voiture',     icon: 'car',                  priceKm: '0.004',   speedKm: 90, maxDistance: 1000 , maxWeight: 500 },
    },
    'delivery' : { 
      'bicyclecargo' :  { name: 'Vélo cargo',  icon: 'bicycle-cargo',        priceKm: '0.001',   speedKm: 10, maxDistance: 10   , maxWeight: 50 },
      'horse'   :       { name: 'Cheval',      icon: 'horse-human',          priceKm: '0.002',   speedKm: 15, maxDistance: 20   , maxWeight: 500 },
      'truck'   :       { name: 'Fourgon',     icon: 'truck-minus-outline',  priceKm: '0.005',   speedKm: 80, maxDistance: 1000 , maxWeight: 20000 },
    }
  }
});

const actions = {
  async setRequestParams({ commit }, params) {
    commit("setRequestParams", params)
  },
  async clearResults({ commit }) {
    commit("clearResults")
  },
  async fetchMap({ commit, state }, params) {

    if(params == null) params = state.requestParams
    if(params.coordinates == null)
      params.coordinates = [46.95026224218562, 2.5913274703850764]

    commit("setRequestParams", params)
    //console.log("fetchMap", params)

    commit("clearResults")
    commit("setStreamLoading", true)
    axios.post('/map/search', params)
        .then((res) => {
          commit("setStreamLoading", false)
          if(!res.data.error) commit("setResults", res.data)
          else console.log("error fetching map", res.data)
        })
    
  },
  async fetchNextMap({ commit, state }) {

    let params = state.requestParams
    if(params.coordinates == null)
      params.coordinates = [46.95026224218562, 2.5913274703850764]

    console.log("fetchNextMap", state.requestParams)
    
    commit("nextResult")
    commit("setStreamLoading", true)
    const { data } = await axios.post('/map/search', state.requestParams)
    commit("setStreamLoading", false)

    if(!data.error) commit("addResults", data)
    else console.log("error fetching map", data)
  },
  selectEntityType({ commit }, catId){
    commit("selectEntityType", catId)
  },
  selectNoEntityType({ commit }){
    commit("selectNoEntityType")
  },

};

const mutations = {
  setRequestParams(state, params) {
    state.requestParams = params
  },
  setStreamLoading(state, bool) {
    state.streamLoading = bool
  },
  setIsEndOfStream(state, bool) {
    state.isEndOfStream = bool
  },
  selectNoEntityType(state) {
    state.entityTypeSelected = []
  },
  selectEntityType(state, catId){
    if(state.entityTypeSelected.indexOf(catId) == -1)
      state.entityTypeSelected.push(catId)
    else
      state.entityTypeSelected.splice(state.entityTypeSelected.indexOf(catId), 1)
  },
  clearResults(state) {
    state.requestParams.skip = 0
    state.results = {
      users: {
        countRes: 0,
        entities: []
      },
      companies: {
        countRes: 0,
        entities: []
      }
    }
    console.log("clearResults", state.results)
  },
  setResults(state, data) {
    state.results = data.results
    state.countResultsTotal = data.countResultsTotal
    state.countResultsTotalLastPart = data.countResultsTotalPart

    state.entities = []
    state.results.users.entities.forEach((entity)=>{
      state.entities.push(entity)
    })
    state.results.companies.entities.forEach((entity)=>{
      state.entities.push(entity)
    })

    state.entities.sort((a, b) =>{ 
      return (new Date(a.updated) < new Date(b.updated)) ? 1 : -1
    })

    if(data.results.users.entities.length < state.requestParams.fetchLimit
    && data.results.companies.entities.length < state.requestParams.fetchLimit)
          state.isEndOfStream = true
    else  state.isEndOfStream = false
  },
  addResults(state, data) {
    //state.results = data.results
    state.countResultsTotal = data.countResultsTotal
    state.countResultsTotalLastPart += data.countResultsTotalPart

    data.results.users.entities.forEach((entity)=>{
      state.entities.push(entity)
    })
    data.results.companies.entities.forEach((entity)=>{
      state.entities.push(entity)
    })
    
    state.entities.sort((a, b) =>{ 
      return (new Date(a.updated) < new Date(b.updated)) ? 1 : -1
    })

    if(data.results.users.entities.length < state.requestParams.fetchLimit
    && data.results.companies.entities.length < state.requestParams.fetchLimit)
          state.isEndOfStream = true
    else  state.isEndOfStream = false
  },
  nextResult(state){
    state.requestParams.skip = state.requestParams.skip + 1
  },
};

const getters = {
  
}

export default {
  namespaced: true,
  getters,
  state,
  actions,
  mutations
};
