
//import axios from "axios"
//import { io } from "socket.io-client";
//import { i18n } from 'vue-lang-router';

const state = () => ({
  
  showDialogTransaction : false,
  productToBye: null
  
});

const actions = {
  async initTransaction({ commit }, product) {
    commit("openDialogTransaction")
    commit("setProductToBye", product)
  },
  

};

const mutations = {
  openDialogTransaction(state, params) {
    state.requestParams = params
  },
  setProductToBye(state, product) {
    state.productToBye = product
  },
  
};

const getters = {
  
}

export default {
  namespaced: true,
  getters,
  state,
  actions,
  mutations
};
