<template>
<v-dialog  :fullscreen="$vuetify.breakpoint.width < 600"
            v-model="showDialog" max-width="500"
            scrollable
  >
  <v-card id="dialogWallet" v-if="company != null">
    <v-toolbar elevation="0" max-height="60">
      <!-- <v-icon  class="mr-1">mdi-view-dashboard-variant</v-icon>  -->
      <v-gravatar height="25" style="border-radius:50%; margin-bottom:-5px;"
                  :default-img="'retro'" :email="company.name" class="mr-1"/>
      <b class="ml-1">{{ company.name }}</b>
      <v-spacer/>
      <!-- <v-btn fab elevation="0" x-small @click="$store.dispatch('auth/refreshUserData')" 
            title="actualiser la quantité de stocks disponibles de chaque produit (il est possible que les stocks aient changés depuis le chargement des données)"
            class="mr-2">
        <v-icon small class="">mdi-refresh</v-icon>
      </v-btn> -->
      <v-btn @click="showDialog = false" 
              outlined icon small class="mr-0">
              <v-icon>mdi-close</v-icon>
      </v-btn>
    </v-toolbar>

    <v-card-text class="pt-2 px-3 pb-0">
      <v-col cols="12" class="px-2">
        <v-btn  v-for="i in 1, company.employees.length" class="mx-1" 
                x-small fab color="green" 
                :key="i" outlined>
          <v-icon style="font-size:22px!important;" class="mb-1">mdi-account-tie</v-icon>
        </v-btn>
        <v-btn  v-for="i in 1, nbEmployeeNeeded" class="ma-1" 
                x-small fab color="teal" title="afficher l'offre d'emplois"
                :key="i+'p'" outlined>
          <v-icon style="font-size:22px!important;" class="mb-1">mdi-account-tie</v-icon>
        </v-btn><br>
        <b class="mx-1 teal--text">
          {{ nbEmployeeNeeded }} poste{{ nbEmployeeNeeded>1?'s':'' }} à pourvoir
        </b>
      </v-col>
      <v-divider/>
      <v-card-subtitle class="teal--text">Souhaitez-vous travailler pour cette entreprise ?</v-card-subtitle>

      <v-col cols="12" class="">
        <v-card outlined class="mb-3">
          <v-card-text>
            <i>
              <template v-if="company.jobOfferText != null && company.jobOfferText != ''">
                {{ company.jobOfferText }}
              </template>
              <template v-else>
                Nous sommes à la recherche d'employés motivés et dynamiques pour travailler à nos côtés !
                Vous avez besoin d'un salaire pour acheter plein de truc ? 
                Alors n'hésitez pas à accepter le job, et rejoignez notre super entreprise !
              </template>
            </i>
            <br>
            <br>
            <b>Salaire : {{ company.salaryEmployees }} òto/mois</b>
          </v-card-text>
        </v-card>

        <v-alert color="red" outlined v-if="isOwner">
          Vous êtes le propriétaire de cette entreprise. 
          Vous ne pouvez pas être employé dans votre propre entreprise.
        </v-alert>
        <v-alert color="red" outlined v-if="isEmployee">
          Vous êtes déjà employé dans cette entreprise.
          Vous ne pouvez pas travailler 2 fois au même endroit.
        </v-alert>
        <v-alert color="red" outlined v-if="isEmployed">
          Vous êtes déjà employé dans une autre entreprise.
          Vous ne pouvez pas cumuler plusieurs emplois salariés en même temps.
        </v-alert>
      </v-col>
    </v-card-text>

    <v-toolbar>
      <v-spacer/>
      <v-btn color="grey" elevation="0" @click="showDialog=false" class="mr-2">
        <v-icon small>mdi-cancel</v-icon> <span class="d-none d-sm-inline">Pas intéressé</span>
      </v-btn>
      <v-btn color="green" elevation="0" :disabled="nbEmployeeNeeded <= 0 || !canBeEmployee" @click="becomeEmployee">
        <v-icon>mdi-check</v-icon>
        Accepter l'offre d'emplois
      </v-btn>
    </v-toolbar>
  </v-card>
</v-dialog>
</template>

<script>

import Gravatar from 'vue-gravatar'

import axios from "axios"

export default {
  components: { 'v-gravatar' : Gravatar },
  data: () => ({
    showDialog: false,
    company: null
  }),
  async mounted(){    
    this.$root.$off('openDialogBecomeSalary')
              .$on('openDialogBecomeSalary', (company) => { 
      this.showDialog = true
      this.company = company
    })
    this.$root.$off('closeDialogBecomeSalary')
              .$on('closeDialogBecomeSalary', () => { 
      this.showDialog = false
      this.company = null
    })
  },
  methods: {
    becomeEmployee(){
      axios.post('/company/become-employee', { companyId: this.company._id })
          .then((res) => {
            if(!res.data.error) {
              this.$root.$emit("updateCompanyPage")
              this.showDialog = false
            }
            else console.log("error in become-employee", res.data)
          })
    }

  },
  computed: {
    
    nbEmployeeNeeded(){
      return (this.company.surface / this.company.surfaceParcelle * this.company.nbEmployeesByParcelle) - this.company.employees.length - 1
    },
    
    isEmployed(){
      let isEmployed = false
      if(this.$store.state.auth.user.employeeIn != null)
      this.$store.state.auth.user.employeeIn.forEach((comp)=>{
        if(this.company._id == comp._id)
          isEmployed = true
      })
      return isEmployed
    },
    
    isEmployee(){
      let isEmployee = false
      console.log("this.company.employees", this.company.employees)
      this.company.employees.forEach((employee)=>{
        if(employee == this.$store.state.auth.user._id)
          isEmployee = true
      })
      return isEmployee
    },
    isOwner(){
      console.log("isOwner?", this.company.userOwner._id, this.$store.state.auth.user._id)
      if(this.company.userOwner._id == this.$store.state.auth.user._id) return true 
      if(this.company.userOwner == this.$store.state.auth.user._id) return true 
      else return false
    },
    canBeEmployee(){
      return !this.isOwner && !this.isEmployee && !this.isEmployed
    },
    
  }
}
</script>
