<template>
<v-dialog  :fullscreen="$vuetify.breakpoint.width < 600"
             v-model="showDialog" max-width="800" scrollable content-class="dialog-large-bordered">
  <v-card id="dialogLoan" style="border-radius:0px;" color="" v-if="$store.state.auth.isLogin">
    <v-toolbar elevation="0" max-height=60>
      <v-icon  class="mr-1">mdi-cash</v-icon> Faire un emprunt à la CCI
      <v-spacer/>
      <v-btn fab elevation="0" x-small @click="$store.dispatch('auth/refreshUserData')" 
            title="actualiser les données"
            class="mr-2">
        <v-icon small class="">mdi-refresh</v-icon>
      </v-btn>
      <v-btn @click="showDialog = false" 
              outlined icon small class="mr-0">
              <v-icon>mdi-close</v-icon>
      </v-btn>
    </v-toolbar>

    <v-card-text class="pt-5 px-4">

      <v-card-title>
        Emprunteur
      </v-card-title>
      <!-- <v-card-subtitle>
        Pour qui souhaitez-vous emprunter ?
      </v-card-subtitle> -->

      <v-row class="mx-0 px-3">
        <v-col cols="12" sm="6" md="6" class="pa-2" v-if="entity == null || entity.dataType == 'user'">
          <v-alert elevation="0" outlined  border="left" 
                    @click="selectBorrower($store.state.auth.user)" style="cursor:pointer;">
            <v-gravatar height="30" style="border-radius:50%;margin: 0 5px -10px 5px;"
                                :default-img="'retro'" :email="$store.state.auth.user.name"/>
            {{ $store.state.auth.user.name }}
          </v-alert>
        </v-col>
        <template v-for="company in $store.state.auth.user.companies">
          <v-col cols="12" sm="6" md="6" class="pa-2" :key="company._id" 
                  v-if="entity == null || (entity.dataType == 'company' && entity._id == company._id)">
            <v-alert elevation="0" outlined border="left" 
                    @click="selectBorrower(company)" style="cursor:pointer;">
              <v-gravatar height="30" style="border-radius:50%;margin: 0 5px -10px 5px;"
                                  :default-img="'retro'" :email="company.name"/>
              {{ company.name }}
            </v-alert>
          </v-col>
        </template>
      </v-row>

      <template v-if="borrower != null">
        <v-card-title>
          Capacité d'emprunt maximum :
        </v-card-title>

        <v-card-subtitle>
          Selon vos revenus et réserves actuels, vous pouvez emprunter au maximum...
        </v-card-subtitle>

        <v-alert border="left" outlined color="amber" class="pl-5 mx-4">
          {{ (maxAmountMensuality*120).toFixed(2) }} òto sur 10 ans,

          soit {{ maxAmountMensuality }} òto/mois,<br>
          <small>ou {{ monyToEuro(maxAmountMensuality*120).toFixed(0) }} € sur 10 ans, soit {{ monyToEuro(maxAmountMensuality) }} €/mois.</small>
        </v-alert>

        <v-col cols="12">
          <v-divider/><br>
          <v-icon class="mb-1 mr-1" >mdi-wallet</v-icon>
          <b class="font-main">De combien avez-vous besoin ?</b><br>
          <small>Indiquez le montant total de ce que vous projetez d'acheter...</small>
          <v-chip color="blue" class="ml-3" outlined
                  :title="monyToEuro(amountTotal).toFixed(2)+' €/m'">
            <b class="pr-1">{{ amountTotal.toFixed(2) }}</b> òto
          </v-chip>
          <v-slider v-model="amountTotal" :min="10" :max="maxAmountMensuality*120" step="10"/>


          <v-icon class="mb-1 mr-1" >mdi-wallet</v-icon>
          <b class="font-main">Apport personnel</b><br>
          Votre compte de dépot dispose de {{ $store.getters['auth/totalDepositMony'].toFixed(2) + ' òto'}}<br>
          <small>Indiquez le montant de l'apport personel que vous êtes prêt à investir</small>
          <v-chip color="blue" class="ml-3" outlined
                  :title="monyToEuro(apportPerso).toFixed(2)+' €/m'">
            <b class="pr-1">{{ apportPerso.toFixed(2) }}</b> òto
          </v-chip>
          <v-slider v-model="apportPerso" min="5" :max="$store.getters['auth/totalDepositMony'].toFixed(2)"/>
        
          <v-alert color="" class="" outlined border="left">
            <v-icon small color="" class="mr-1">mdi-star</v-icon>
            Vous avez donc besoin d'emprunter <b>{{ financeNeed }} òto</b>
          </v-alert>
        </v-col>
        
        <v-card-title><v-icon>mdi-chevron-down</v-icon> Définissez les paramètres de votre emprunts</v-card-title>


        <template v-if="!outOfCapacity">
          <v-card outlined class="px-3">
            <v-toolbar color="transparent" dense elevation="0" class="mt-5 px-2 toolbar-no-padding">
              Nombre de mensualités
              <v-chip color="teal" class="ml-3" style="font-size:20px;"
                      :title="(nbMensuality/12).toFixed(1)+' ans'">
                <b class="pr-1">{{ nbMensuality.toFixed(1) }}</b>
                <small class="ml-2">~{{ (nbMensuality/12).toFixed(1)+' an' }}{{ nbMensuality>1 ? 's':'' }}</small>
              </v-chip>
            </v-toolbar>
            <v-col cols="12" class="px-0 py-0">
              <v-slider v-model="nbMensuality" :min="parseInt(minNbMensuality)+1" :max="parseInt(maxNbMensuality)"
                        :color="colorLoan" hide-details
                        @change="calcAmountMensuality"></v-slider>

            </v-col>
            <v-toolbar color="transparent" dense elevation="0" class="mb-5 toolbar-no-padding" height="18">
              <v-chip small color="transparent" class="mr-2">Min {{ parseInt(minNbMensuality)+1 }} mois</v-chip>
              <v-spacer/>
              <v-chip small color="transparent">Max {{ parseInt(maxNbMensuality) }} mois</v-chip>
            </v-toolbar>
          </v-card>
          

          <v-card outlined class="px-3 mt-5">
            <v-toolbar color="transparent" dense elevation="0" class="mt-5 pl-2 toolbar-no-padding">
              Montant des mensualités
              <v-chip color="teal" class="ml-3" style="font-size:20px;"
                      :title="monyToEuro(amountMensuality).toFixed(2)+' €/m'">
                <b class="pr-1">{{ amountMensuality.toFixed(2) }}</b> òto/mois
                <small class="ml-3 d-none d-sm-inline">
                  ~{{ monyToEuro(amountMensuality).toFixed(2)+' €/m' }}
                  {{ nbMensuality>1 ? 's':'' }}
                </small>
              </v-chip>
            </v-toolbar>
            <v-col cols="12" class="px-0 py-0">
              <v-slider v-model="amountMensuality" hide-details
                        :min="minAmountMensuality+0.01" :max="maxAmountMensuality" :step="0.01"
                        :color="colorLoan" @change="calcNbMensuality"></v-slider>
            </v-col>
            <v-toolbar color="transparent" dense elevation="0" class="mb-5 toolbar-no-padding" height="18">
              <v-chip small color="transparent" class="mr-2">Min {{ minAmountMensuality }} òto</v-chip>
              <v-spacer/>
              <v-chip small color="transparent">Max {{ maxAmountMensuality }} òto</v-chip>
            </v-toolbar>
          </v-card>
        </template>

        <v-card class="pa-3 mt-5" elevation="0" outlined>
          Prélèvement quotidien :
          <b class="teal--text">{{ (amountMensuality/30.4375).toFixed(6) }} òto/jour</b><br>
          <small>Le montant des mensualités est automatiquement prélevé sur vos comptes <b>chaque jour</b>.</small>
        </v-card>

        <v-col cols="12">
          - En souscrivant cet emprunt auprès de la CCI, vous vous engagez à rembourser quotidiennement la somme de
          {{ (amountMensuality/30.4375).toFixed(6) }} òto.
          Cette somme sera prélevée automatiquement sur votre compte de dépot, 
          ou à défaut de crédit suffisant, sur votre compte courant.
          <br>
          - Vous pourrez à tout moment rembourser tout ou partie de la somme empruntée, afin de réduire le nombre de mensualité restante.
          <br>
          - Le montant des mensualités ne peut pas être modifié ultérieurement.
          <br>
          - Le montant de {{ financeNeed }} òto sera versé immédiatement sur votre compte de dépot dès votre souscription.
        </v-col>

        <v-toolbar color="transparent" elevation="0">
          <v-spacer/>
          <v-btn color="green" large rounded @click="save">
            <v-icon small color="" class="mr-1">mdi-check</v-icon>
            Souscrire
          </v-btn>
        </v-toolbar>
      </template>
      
    </v-card-text>
  </v-card>
</v-dialog>
</template>

<style scoped>
  .borrower-selected{
    border: 1px solid #4caf50;
  }
</style>

<script>

import axios from 'axios'
//import router from '../router/router'
//import core from '../plugins/core.js'

//import Post from '@/components/Post.vue'
const config = require('../../config/' + process.env.NODE_ENV)
import Gravatar from 'vue-gravatar'

export default {
  name: 'DialogLoan',
  props: ['houseSelected'],
  components: { 'v-gravatar' : Gravatar },
  data: () => ({
    showDialog: false,
    entity: null,
    borrower: null,
    nbMensuality: 120,
    amountMensuality: 1,
    apportPerso: 10,
    loanMaxDuration: 120,
    percentReserve: 50,
    amountTotal: 100,
  }),
  async mounted(){
    this.$root.$off('openDialogLoan')
              .$on('openDialogLoan', (entity) => { 
      if(this.$store.state.auth.user.companies.length == 0)
        this.borrower = this.$store.state.auth.user

      if(entity != null){
        this.entity = entity
        this.borrower = entity
      }

      this.showDialog = true
    })

    this.$root.$off('closeDialogLoan')
              .$on('closeDialogLoan', () => { 
      this.showDialog = false
    })

    console.log("this.$store.state.auth.user.companies.length", this.$store.state.auth.user.companies.length)
  },
  methods: {
    monyToEuro(amount){ return amount * config.monyToEuro },
    calcNbMensuality(){
      this.nbMensuality = this.financeNeed / this.amountMensuality
    },
    calcAmountMensuality(){
      this.amountMensuality = this.financeNeed / this.nbMensuality
    },
    selectBorrower(borrower){
      this.borrower = borrower
      this.calcNbMensuality()
    },
    save(){
      let params = {
        borrowerCompanyId: this.borrower.dataType=="company" ? this.borrower._id : null,
        amountTotal: this.financeNeed,
        amountMensuality: this.amountMensuality,
        nbMensuality: this.nbMensuality,
      }

      axios.post("/game/save-loan", params)
           .then(res =>{
             if(res.data.error == false){
              console.log("ok", res.data)
              this.showDialog = false
              this.$store.dispatch('auth/refreshUserData')
              this.$store.dispatch('app/openSnackbar', 
                            { show: true, color:'green', 
                              title: "Votre emprunt a été enregistré !",
                              text: 'Le montant de ' + this.financeNeed+ ' viens d\'être versé sur votre compte de dépot.',
                              icon: 'thumb-up' })
             }else{
              if(res.data.key == 'CCI_over_capacity')
              this.$store.dispatch('app/openSnackbar', 
                            { show: true, color:'red', 
                              title: "Echec de l'emprunt",
                              text: 'Désolé, la CCI ne dispose plus des fonds nécessaire pour vous accorder cet emprunt.',
                              icon: 'thumb-down' })
             }
           })
    }
  },
  computed: {
    financeCapacity(){
      return (this.maxAmountMensuality * 120)
    },
    maxAmountMensuality(){
      if(this.borrower == null) return 0
      if(this.borrower.dataType == "user"){
        let salary = 0
        this.$store.state.auth.user.employeeIn.forEach((company) => {
          salary += company.salaryEmployees
        })
        let reserve = (this.$store.getters['auth/amountTotalCurrent'] / 30)
        let max = (salary / 10) + reserve 
        return max.toFixed(2)
      }
      else if(this.borrower.dataType == "company"){
        let benef = 5
        //calcul les bénéfices des 7 derniers jours de l'entreprise
        let date7D = new Date()
        date7D.setDate(date7D.getDate() - 7)
        this.borrower.walletDeposit.transactions.forEach((trans) => {
          console.log("trans.fromWallet", trans.fromWallet.ownerName)
          if(trans.fromWallet.ownerName != "Caisse Commune d'Investissement"
          && date7D < trans.created){
            if(trans.toWallet.id == this.borrower.walletDeposit._id)
              benef += trans.amountMony
            else
              benef -= trans.amountMony
          }
        })
        console.log("benef", benef)
        let reserve = (this.borrower.walletDeposit.amountMony / 30)
        let max = (benef / 10) + reserve 
        return max.toFixed(2)
      }
      return 0
    },
    maxNbMensuality(){
      let max = (this.financeNeed / this.minAmountMensuality).toFixed(2)
      //if(max > 120) max = 120
      return max
    },
    minAmountMensuality(){
      let min = (this.financeNeed / (this.loanMaxDuration)).toFixed(2)
      return min
    },
    minNbMensuality(){
      let min = (this.financeNeed / this.maxAmountMensuality).toFixed(2)
      return min
    },
    outOfCapacity(){
      return this.minNbMensuality > this.loanMaxDuration
    },
    colorLoan(){
      console.log("colorLoan", this.nbMensuality, this.loanMaxDuration, this.minAmountMensuality, this.maxAmountMensuality)
      if(this.nbMensuality > this.loanMaxDuration) return 'red'
      if(parseFloat(this.minAmountMensuality) > parseFloat(this.maxAmountMensuality)) return 'red'
      return 'teal'
    },
    financeNeed(){
      return this.amountTotal - this.apportPerso
    },
  }
}
</script>
