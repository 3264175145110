<template>
<v-card outlined color="background_dark" elevation="0" v-if="company != null">
  <v-row class="ma-0">
    <v-col cols="12" class="">
      <v-gravatar height="40" style="border-radius:50%; margin-bottom:-15px;"
                  :default-img="'retro'" :email="company.name" class="mr-2"/>

      <b>{{ company.name }}</b>
      <v-divider class="mt-5"/> 
      <v-card-title v-if="isEmployee">Salaire : {{ company.salaryEmployees }} òto/mois</v-card-title>
      <v-btn block elevation="0" outlined color="grey" v-if="isEmployee">
        <v-icon small>mdi-exit-run</v-icon> Démissioner
      </v-btn>
    </v-col>
  </v-row>

</v-card>
</template>

<style></style>

<script>

//import axios from 'axios'
//import router from '../router/router'
//import core from '../plugins/core.js'

import Gravatar from 'vue-gravatar'

export default {
  name: 'companycard',
  components: { 'v-gravatar' : Gravatar, },
  props: ['company'],
  data: () => ({
    
    
  }),
  async mounted(){
    
  },
  methods: {
    

  },
  computed: {
    isEmployee(){
      let is = false 
      this.$store.state.auth.user.employeeIn.forEach(company => {
        if(company == this.company) is = true
      })
      return is
    }
    
  }
}
</script>
