<template>
<v-card outlined color="background_dark" elevation="0" v-if="company != null">
  <v-row class="ma-0">
    <v-col cols="12" class="">
      <v-gravatar height="25" style="border-radius:50%; margin-bottom:-5px;"
                  :default-img="'retro'" :email="company.name" class="mr-1"/>

      <b>{{ company.name }}</b>
      <v-spacer class="my-1"/> 
      <template v-if="company.walletDeposit != null">
        <v-chip color="secondary" @click="$root.$emit('openDialogWallet', company)"  
                class="mb-1 pa-1 px-2" height="24" elevation="0">
          <v-icon small class="mb-0 mr-1">mdi-wallet</v-icon>
          <b>{{ company.walletDeposit.amountMony.toFixed(4) }} òto</b>
        </v-chip>
        · {{ company.walletDeposit.uid }} 
        <v-spacer/>
      </template>
    
      <v-menu offset-y>
        <template v-slot:activator="{ on, attrs }">
          <v-btn color="" text dark elevation="0"
                 v-bind="attrs" v-on="on" min-width="0"
                 title="Commandes fournisseurs · IN"
                 class="mt-0 pa-1 mx-0" height="24">
            <v-icon small class="">mdi-package-variant</v-icon>
            <v-icon small class="">mdi-chevron-double-left</v-icon>
            <v-icon small class="mr-1">mdi--newspaper-variantoutline</v-icon>
            {{ company.commandesIn.length }}
          </v-btn>
        </template>
        <v-list>
          <v-list-item v-for="commande in company.commandesIn"
                      @click="$root.$emit('openDialogEntity', commande.sellerCompany, commande)"
                      :key="commande._id">
            <v-list-item-title>{{ commande.sellerCompany.name }}</v-list-item-title>
          </v-list-item>
        </v-list>
      </v-menu>
      <v-menu offset-y>
        <template v-slot:activator="{ on, attrs }">
          <v-btn color="" text dark elevation="0"
                 v-bind="attrs" v-on="on" min-width="0"
                 title="Commandes clients · OUT"
                 class="mt-0 pa-1 ml-2" height="24">
            <v-icon small class="">mdi-store</v-icon>
            <v-icon small class="">mdi-chevron-double-right</v-icon>
            {{ company.commandesOut.length }}
          </v-btn>
        </template>
        <v-list>
          <v-list-item v-for="commande in company.commandesOut"
                      @click="$root.$emit('openDialogShowCommande', commande)"
                      :key="commande._id">
            <v-list-item-title>
              {{ commande.buyerCompany != null ? commande.buyerCompany.name : commande.buyerUser.name }}
            </v-list-item-title>
          </v-list-item>
        </v-list>
      </v-menu>
    </v-col>

    <v-col cols="12" class="pt-0" v-if="company.productsIn.length">
      <v-divider class="mb-2"/> 
      <v-icon title="Produits en stock" color="">mdi-package-variant</v-icon>
      <template v-for="(product, key) in company.productsIn">
          <v-chip outlined :key="key+'-out'"  
                :color="product.iconColor"
                :title="product.name"
                class="ma-1 py-0 pl-2" small>
            <v-icon :color="product.iconColor" class="mr-1">
              mdi-{{ product.icon }}
            </v-icon>
            {{ product.stock.toFixed(2) }} 
            {{ product.priceUnity }}{{ product.stock > 1 ? "s" : "" }}
          </v-chip>
      </template>
    </v-col>

    <v-col cols="12" class="pt-0" v-if="company.productsOut.length">
      <v-divider class="mb-2"/> 
      <v-icon title="Produits en boutique" color="">mdi-store</v-icon>
      <template v-for="(product, key) in company.productsOut">
          <v-chip outlined :key="key+'-out'"  
                :color="product.iconColor"
                :title="product.name"
                class="ma-1 py-0 pl-2" small>
            <v-icon :color="product.iconColor" class="mr-1">
              mdi-{{ product.icon }}
            </v-icon>
            {{ product.stock.toFixed(2) }} 
            {{ product.priceUnity }}{{ product.stock > 1 ? "s" : "" }}
          </v-chip>
      </template>
    </v-col>
    <v-col cols="12" class="pt-0">
      <v-btn  elevation="0" block class="" 
              title="Gérer mon entreprise"
              @click="openDialogManageCompany">
        <v-icon small class="mr-1">mdi-cogs</v-icon> Gérer mon entreprise
      </v-btn>
    </v-col>

  </v-row>

</v-card>
</template>

<style></style>

<script>

//import axios from 'axios'
//import router from '../router/router'
//import core from '../plugins/core.js'

import Gravatar from 'vue-gravatar'

export default {
  name: 'companycard',
  components: { 'v-gravatar' : Gravatar, },
  props: ['company'],
  data: () => ({
    
    
  }),
  async mounted(){
    
  },
  methods: {
    openDialogManageCompany(){
      this.$root.$emit("openDialogManageCompany", this.company)
    }

  },
  computed: {
    
    
  }
}
</script>
