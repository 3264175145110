import { render, staticRenderFns } from "./DialogCatalog.vue?vue&type=template&id=21d44fad&scoped=true&"
import script from "./DialogCatalog.vue?vue&type=script&lang=js&"
export * from "./DialogCatalog.vue?vue&type=script&lang=js&"
import style0 from "./DialogCatalog.vue?vue&type=style&index=0&id=21d44fad&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "21d44fad",
  null
  
)

export default component.exports