<template>
  <v-card outlined class="text-center py-3 mb-3">
    <v-icon :color="house.iconColor" x-large class="">
      mdi-{{ house.icon }}
    </v-icon>
    <br>
    <b :class="house.iconColor + '--text'">{{ house.name }}</b>
    <v-divider class="my-2"/>

    <v-simple-table style="width:100%" dense>
      <tbody>
        <tr>
          <td class="text-left">Nombre de pièces</td>
          <td class="text-right">{{ house.housing.nbRoom }}</td>
        </tr>
        <tr v-if="!small">
          <td class="text-left">Salles de bain</td>
          <td class="text-right">{{ house.housing.nbSDB }}</td>
        </tr>
        <tr>
          <td class="text-left">Surface terrain</td>
          <td class="text-right">{{ house.housing.surfaceLand }} m2</td>
        </tr>
        <tr>
          <td class="text-left">Surface logement</td>
          <td class="text-right">{{ house.housing.surfaceHouse }} m2</td>
        </tr>
        <tr v-if="!small">
          <td class="text-left">Garage</td>
          <td class="text-right">{{ house.housing.garage ? 'oui' : 'non' }}</td>
        </tr>
        <tr v-if="!small">
          <td class="text-left">Piscine</td>
          <td class="text-right">{{ house.housing.piscine ? 'oui' : 'non' }}</td>
        </tr>
        <tr>
          <td class="text-left">Qualité</td>
          <td class="text-right">{{ house.quality }}%</td>
        </tr>
        <tr>
          <td class="text-left">Prix</td>
          <td class="text-right">
            {{ parseInt(house.price) }} òto<br>
            <small>~ {{ priceEuro }}€</small>
          </td>
        </tr>
      </tbody>
    </v-simple-table>
  </v-card>
</template>


<script>

const config = require('../../config/' + process.env.NODE_ENV)

export default {
  name: 'empty',
  props: ['house', 'small'],
  components: { },
  data: () => ({
    
  }),
  async mounted(){    
    
  },
  methods: {
    
    monyToEuro(amount){ return amount * config.monyToEuro },
  },
  computed: {
     priceEuro(){
       let price = parseInt(this.monyToEuro(this.house.price)) + ""
       console.log("price.length ", price.length )
       if(price.length > 3) price = price.substr(0, price.length-3) + " " + price.substr(price.length-3, 3)
       return price
     }
  }
}
</script>
