<template>
<div>
  <v-row class="mx-0">
    <template v-for="(product) in vehiclesList">
      <v-col :key="product._id" cols="6" md="6"  lg="3" class="py-0 px-0 mx-auto">
        <v-menu open-on-click bottom offset-y>
          <template v-slot:activator="{ on, attrs }">
            <v-card elevation="0" class="pa-3 py-3 text-center" 
                    v-bind="attrs" v-on="on">
              <v-progress-circular :rotate="-90" :size="80" :width="10"
                :value="product.kmToday / product.kmByDay * 100" 
                :color="(product.kmToday / product.kmByDay * 100 < 50) ? 'green' : (product.kmToday / product.kmByDay * 100 < 70) ? 'orange' : 'red'"
                :title="'utilisation aujourd\'hui ' + product.kmToday / product.kmByDay * 100 + '%'"
              >
              <v-badge :color="product.stock > 0 ? 'green' : 'grey'" 
                        style="font-weight:bold!important" :content="product.stock+''">
                <v-icon large>mdi-{{ product.icon }}</v-icon>
              </v-badge>
              </v-progress-circular>
              <br>
              <small style="margin-top:-30px;">
                <b>{{ product.kmToday / product.kmByDay * 100 }} %</b>
              </small>
            </v-card>
          </template>
          <v-card class="pa-3" max-width="400">
            <template v-if="product.stock > 0">
              Vous avez {{ product.stock }} {{ product.name.toLowerCase() }}{{ product.stock>1?'s':'' }}.
              <v-divider class="my-2"/>
              Les {{ product.name.toLowerCase() }}s peuvent parcourir {{ product.kmByDay }} km par jour chacun.
              Ils ont parcouru {{ product.kmToday }} km au total aujourd'hui.
              Ils peuvent encore parcourir {{ product.kmByDay - product.kmToday}} km aujourd'hui.
            </template>
            <template else>
              Vous n'avez pas de {{ product.name.toLowerCase() }}.
              <v-divider class="my-2"/>
              <template v-if="isCompany">
                Vous pouvez utiliser vos {{ product.name.toLowerCase() }}s pour effectuer vous-mêmes
                les livraisons de vos commandes d'approvisionnements.
              </template>
              <template v-else>
                Les {{ product.name.toLowerCase() }}s peuvent être utilisés pour vous déplacer.
              </template>
              Ils peuvent parcourir {{ product.kmByDay }} km par jour chacun,
              et transporter {{ product.weightCapacity }} kg, 
              sur une distance maximum de {{ product.distanceCapacity }} km.
            </template>
          </v-card>
        </v-menu>
      </v-col>
    </template>
  </v-row>
</div>
</template>


<script>

//import axios from 'axios'
//import router from '../router/router'
//import core from '../plugins/core.js'


const config = require('../../config/' + process.env.NODE_ENV)

export default {
  name: 'empty',
  components: {  },
  props: ['vehiclesList', 'isCompany'],
  data: () => ({
    
  }),
  async mounted(){    
    
  },
  methods: {
   
    monyToEuro(amount){ return amount * config.monyToEuro },
  },
  computed: {
    
    
  }
}
</script>
